import React, { useEffect } from 'react';
import styled from 'styled-components';
import Section from './components/Section';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import ContactInfo from './components/ContactInfo';
import PersonalInfo from './components/PersonalInfo';
import Address from './components/Address';
import Confirmation from './components/Confirmation';
import VerifyingDocument from './components/VerifyingDocument';
import { sendForm } from '../../services/requests';
import Button from '../../components/Button';
import { colors } from '../../constants/theme';
import { ReactComponent as LogoIcon } from '../../assets/svg/logo.svg';
import { useModal } from '../../components/ModalProvider';
import PaymentModal from '../../components/Modal/PaymentModal';
import { useAppDispatch } from '../../store/hooks';
import { fetchPlanThunk } from '../../store/plan';
import ReactPixel from 'react-facebook-pixel';
import { useQuery } from '../../utils/common';
import {
  CONFIRMATION_DESCRIPTION,
  validate,
  VERIFYING_DESCRIPTION,
} from './formPage.helpers';
import { gtag } from '../../services/gtag';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { scroller } from 'react-scroll';
import Loader from '../../components/Loader';

const INITIAL_VALUE = {
  firstName: '',
  lastName: '',
  middleName: '',
  gender: 'male',
  state: '',
  email: '',
  zip: '',
  city: '',
  address: '',
  height: '',
  weight: '',
  allergies: '',
  medical: '',
  phone: '',
  signature: '',
  stateId: '',
  agreement: false,
  id_card: null,
  qualifyingConditions: [],

  month: '',
  day: '',
  year: '',
  date: '',
};

export type Values = {
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  state: string;
  email: string;
  zip: string;
  city: string;
  address: string;
  height: string;
  weight: string;
  allergies: string;
  medical: string;
  phone: string;
  stateId: string;
  id_card: File | null;
  signature: string;
  agreement: boolean;
  qualifyingConditions: Array<string>;

  month: string;
  day: string;
  year: string;
  date: string;
};

function FormPage() {
  const openModal = useModal();
  const dispatch = useAppDispatch();
  const query = useQuery();

  const getPlan = query.get('plan');
  const plan = getPlan ? getPlan : 'default';
  const pix = query.get('pix');

  useEffect(() => {
    dispatch(fetchPlanThunk(plan));
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  /** Connect analytics */
  useEffect(() => {
    gtag.event('page_view', {
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_title: document.title,
      plan: plan,
    });
    if (pix) {
      const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(pix, undefined, options);
      ReactPixel.pageView();
    }
  }, []);

  function handleSubmit(values: Values, formikHelpers: FormikHelpers<Values>) {
    sendForm(values).then((response) => {
      gtag.event('begin_checkout', { plan: plan });
      formikHelpers.setSubmitting(false);
      openModal(
        PaymentModal,
        {
          clientId: response.data.client_id,
          plan: plan ? plan : 'default',
          firstName: values.firstName,
          lastName: values.lastName,
          pix: pix,
        },
        { dontClose: true }
      );
    });
  }

  return (
    <Component>
      <ContentContainer>
        <Header>
          <LogoIcon />
        </Header>
        <Main>
          <Formik<Values>
            initialValues={INITIAL_VALUE}
            onSubmit={handleSubmit}
            validate={validate}
          >
            <Forms />
          </Formik>
        </Main>
        <Footer>
          <Agreement>
            © 2021 mmjcardamericaonline. All rights reserved.
          </Agreement>
          <List>
            <Item>{/*<Link>Return Policy</Link>*/}</Item>
            <Item>{/*<Link>Privacy Policy</Link>*/}</Item>
            <Item>{/*<Link>Terms & Conditions</Link>*/}</Item>
          </List>
        </Footer>
      </ContentContainer>
    </Component>
  );
}

function Forms() {
  const { isSubmitting, errors, isValid } = useFormikContext<Values>();

  useUpdateEffect(() => {
    if (isSubmitting && !isValid) {
      scroller.scrollTo(Object.keys(errors)[0], {
        smooth: true,
        offset: -20,
      });
    }
  }, [errors, isValid, isSubmitting]);

  return (
    <Form>
      <Section title={'Contact Info'}>
        <ContactInfo />
      </Section>
      <Section title={'Personal Info'}>
        <PersonalInfo />
      </Section>
      <Section title={'Address'}>
        <Address />
      </Section>
      <Section title={'Verifying document'} description={VERIFYING_DESCRIPTION}>
        <VerifyingDocument />
      </Section>
      <Section title={'Confirmation'} description={CONFIRMATION_DESCRIPTION}>
        <Confirmation />
      </Section>
      <ButtonBlock>
        {isSubmitting && <Loader size={20} />}
        <Button type={'submit'} variant={'primary'}>
          Submit Document
        </Button>
      </ButtonBlock>
    </Form>
  );
}

export default FormPage;

const Component = styled.div`
  border-bottom: 1px solid ${colors.grayLight};
`;

const Header = styled.header`
  padding: 30px 0;
  border-bottom: 1px solid ${colors.grayLight};
  margin-bottom: 80px;
  @media (max-width: 767px) {
    padding: 20px 0;
    margin-bottom: 40px;
    svg {
      width: 179px;
      height: 50px;
    }
  }
`;

const Main = styled.main`
  border-bottom: 1px solid ${colors.grayLight};
  padding-bottom: 80px;

  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
`;

const Footer = styled.footer`
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
    text-align: center;
    padding: 40px 0;
  }
`;

const Agreement = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.grayDark2};

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const List = styled.ul`
  display: flex;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-right: 30px;
    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

const Link = styled.a`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.grayDark2};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;

const ButtonBlock = styled.div`
  position: relative;
`;
