import React from 'react';
import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { useFormikContext } from 'formik';
import { Values } from '../FormPage';
import FileField from '../../../components/FileField';
import { Element } from 'react-scroll';
import { validateOnlyNumberAndLength } from '../formPage.helpers';

function VerifyingDocument() {
  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormikContext<Values>();

  function handleSetFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files?.length) {
      setFieldValue('id_card', event.currentTarget.files[0]);
    }
  }

  function handleStateIdChange(event: React.ChangeEvent<HTMLInputElement>) {
    validateOnlyNumberAndLength(event, handleChange, 9);
  }
  return (
    <Component>
      <Fields>
        <Element name={'stateId'}>
          <Input
            name={'stateId'}
            value={values.stateId}
            onChange={handleStateIdChange}
            label={'Driver’s license or State ID number'}
            placeholder={'Number of yor driver’s lic. or State ID'}
            helperText={errors.stateId && touched.stateId ? errors.stateId : ''}
            type={'number'}
          />
        </Element>
        <Element name={'id_card'}>
          <FileInput
            name={'id_card'}
            onChange={handleSetFileChange}
            label={'Photo of your document'}
            placeholder={'Upload document'}
            helperText={errors.id_card && touched.id_card ? errors.id_card : ''}
          />
        </Element>
      </Fields>
    </Component>
  );
}
export default VerifyingDocument;

const Component = styled.div``;

const Input = styled(TextField)`
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const FileInput = styled(FileField)`
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  @media (max-width: 767px) {
    display: block;
  }
`;
