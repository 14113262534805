import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/theme';
import BgUrl from '../../../assets/images/home-bg.png';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/arrow-right.svg';
import ContentContainer from '../ContentContainer';
import { useAppSelector } from '../../../store/hooks';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../utils/common';
import { gtag } from '../../../services/gtag';

function FirstSection() {
  const query = useQuery();
  const history = useHistory();

  const getPlan = query.get('plan');
  const plan = getPlan ? getPlan : 'default';

  const { planData } = useAppSelector(
    (state) => ({
      planData: state.plan.data,
    }),
    shallowEqual
  );

  function handleButtonClick() {
    history.push({ pathname: '/form', search: query.toString() });
    gtag.event('navigate_to_form', {
      source_block: 'get_your_mmj_card',
      plan: plan,
    });
  }

  return (
    <Component>
      <Image src={BgUrl} />
      <ContentContainer>
        <Title>
          Get <span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Card to Access the Best <span>C</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> Products
        </Title>
        <Description>
          Receive Approval For Your <span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Recommendation From
          State-Licensed <span>D</span><span>o</span><span>c</span><span>t</span><span>o</span><span>r</span><span>s</span>
        </Description>
        <ButtonLink onClick={handleButtonClick}>
          <ButtonName>Get Your <span>M</span><span>M</span><span>J</span> Card</ButtonName>
          <ButtonDescription>
            Special offer just for ${planData?.price}
          </ButtonDescription>
          <ArrowIcon />
        </ButtonLink>
      </ContentContainer>
    </Component>
  );
}

export default FirstSection;

const Component = styled.section`
  padding: 198px 0 212px;
  background-color: #0b0e0f;
  position: relative;
  @media (max-width: 767px) {
    padding: 141px 0 80px;
  }
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  object-fit: cover;
  height: 100%;
  object-position: 0;
`;

const Title = styled.h1`
  font-size: 62px;
  line-height: 83px;
  font-weight: 400;
  margin: 0 0 34px;
  font-family: 'Playfair Display', sans-serif;
  max-width: 702px;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 18px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 30px;
  max-width: 598px;
  margin-bottom: 40px;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
    max-width: 302px;
    margin-bottom: 25px;
  }
`;

const ButtonLink = styled.button`
  display: flex;
  flex-direction: column;
  background-color: ${colors.green};
  border-radius: 8px;
  padding: 24px 92px 25px 30px;
  position: relative;
  max-width: 309px;
  svg {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
  transition: 0.3s;
  &:hover {
    background-color: ${colors.greenDark};
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: unset;
  }
`;

const ButtonName = styled.span`
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    margin-bottom: 4px;
  }
`;

const ButtonDescription = styled.span`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.greenLight2};
`;
