import React from 'react';
import styled from 'styled-components';
import Signature from './components/Signature';
import { colors } from '../../../constants/theme';
import { ReactComponent as Checkmark } from '../../../assets/svg/checkmark-black.svg';
import BaseCheckbox from '../../../components/BaseCheckbox';
import { useFormikContext } from 'formik';
import { Values } from '../FormPage';

const REQUIREMENTS = [
  'I am over the age of 18',
  'I am a California resident',
  'I don’t have schizophrenia and i don’t have hallucinations',
  'I am not pregnant (for females only)',
];

function Confirmation() {
  const { values, handleChange, errors, touched } = useFormikContext<Values>();

  return (
    <Component>
      <List>
        {REQUIREMENTS.map((requirement) => (
          <Item key={requirement}>
            <Checkmark />
            {requirement}
          </Item>
        ))}
      </List>
      <SignatureBlock>
        <Description>
          Use your mouse, finger or stylus to add your signature.
        </Description>
        <Signature />
        <DesktopCheckbox>
          <Label htmlFor={'agreement'}>
            <Checkbox
              name={'agreement'}
              onChange={handleChange}
              checked={values.agreement}
              id={'agreement'}
              helperText={
                errors.agreement && touched.agreement ? errors.agreement : ''
              }
            />
            I agree to the terms and conditions
          </Label>
        </DesktopCheckbox>
      </SignatureBlock>
      <MobileCheckbox>
        <Label htmlFor={'agreement'}>
          <Checkbox
            name={'agreement'}
            onChange={handleChange}
            checked={values.agreement}
            id={'agreement'}
            helperText={
              errors.agreement && touched.agreement ? errors.agreement : ''
            }
          />
          I agree to the terms and conditions
        </Label>
      </MobileCheckbox>
    </Component>
  );
}
export default Confirmation;

const Component = styled.div``;

const SignatureBlock = styled.div`
  background-color: ${colors.grayLight2};
  padding: 40px 103px;
  text-align: center;
  border-radius: 12px;
  @media (max-width: 767px) {
    padding: 20px;
    margin-bottom: 30px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
`;

const List = styled.ul`
  margin-top: -20px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-top: 0;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid ${colors.grayLight};
  }
`;

const Item = styled.li`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  svg {
    margin-right: 10px;
  }
`;

const DesktopCheckbox = styled.div`
  text-align: center;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileCheckbox = styled.div`
  display: none;
  margin-bottom: 30px;
  text-align: center;
  @media (max-width: 767px) {
    display: block;
  }
`;

const Checkbox = styled(BaseCheckbox)`
  width: auto;
  margin-right: 10px;
`;

const Label = styled.label`
  display: inline-flex;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
`;
