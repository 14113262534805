import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlanType } from '../typings/model';
import { getPlanDetail } from '../services/requests';

import { AppDispatch } from './store';

type State = {
  status: 'idle' | 'pending' | 'success' | 'failed';
  data: PlanType | null;
  error: string | null;
};

const initialState: State = {
  status: 'idle',
  data: null,
  error: null,
};

const slice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    fetchPlanLoading: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    fetchPlanSuccess: (state, action: PayloadAction<PlanType>) => {
      state.status = 'success';
      state.data = action.payload;
    },
    fetchPlanError: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.data = null;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

const { fetchPlanLoading, fetchPlanSuccess, fetchPlanError } = slice.actions;

export function fetchPlanThunk(
  plan: string
): (dispatch: AppDispatch) => Promise<PlanType | null> {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchPlanLoading());
    try {
      const response = await getPlanDetail(plan);

      dispatch(fetchPlanSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(fetchPlanError(error.message));
      return null;
    }
  };
}
