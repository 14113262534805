import React, { FormEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  useRecurly,
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
} from '@recurly/react-recurly';

import { colors } from '../../../../constants/theme';
import Button from '../../../Button';
import { purchaseApplicationPayment } from '../../../../services/requests';

import '../paymentInput.css';
import TextField from '../../../TextField';
import ReactPixel from 'react-facebook-pixel';
import { gtag } from '../../../../services/gtag';
import { useModal } from '../../../ModalProvider';
import SuccessPaymentModal from '../../SuccesPaymentModal';

type Props = {
  clientId: string;
  plan: string;
  firstName: string;
  lastName: string;
  pix: string | null;
  setLoading: (isLoading: boolean) => void;
};

function PaymentForm({
  clientId,
  setLoading,
  plan,
  firstName,
  lastName,
  pix,
}: Props) {
  const recurly = useRecurly();
  const openModal = useModal();

  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidCardMonth, setValidCardMonth] = useState(false);
  const [isValidCardYear, setValidCardYear] = useState(false);
  const [isValidCardCvv, setValidCardCvv] = useState(false);
  const [error, setError] = useState('');

  const formRef = useRef<HTMLFormElement>(null);
  const inputStyle = {
    fontColor: '#000000',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    border: `1px solid ${colors.grayLight}`,
  };

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setError('');
    setLoading(true);

    recurly.token(formRef.current as HTMLFormElement, async (err, token) => {
      if (err) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      } else {
        purchaseApplicationPayment(clientId, token.id, plan)
          .then((response) => {
            if (pix) {
              ReactPixel.track('Purchase', {
                value: response.data.sum,
                currency: 'USD',
              });
            }
            gtag.event('purchase', {
              value: response.data.sum,
              currency: 'USD',
              plan: plan,
            });

            openModal(SuccessPaymentModal, {}, { dontClose: true });

            fetch("https://ads.trafficjunky.net/tj_ads_pt?a=1000242301&member_id=1003020541&cb=" + Math.floor(Math.random() * 9999999).toString() + "&cti=" + Math.floor(Math.random() * 9999999).toString() + "&ctv=" + response.data.sum +"&ctd=" + plan)

          })
          .catch((error) => {
            console.error(error.response?.data);
            setError(error.response.data.error);
          })
          .finally(() => setLoading(false));
      }
    });
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <NameInputs>
        <TextField
          data-recurly="first_name"
          id="first_name"
          value={firstName}
          onChange={() => null}
        />
        <TextField
          data-recurly="last_name"
          id="last_name"
          value={lastName}
          onChange={() => null}
        />
      </NameInputs>
      <CardInputBlock>
        <Label>Card number</Label>
        <CardNumberElement
          style={{
            ...inputStyle,
            placeholder: { content: 'XXXX XXXX XXXX XXXX', color: colors.gray },
          }}
          inputType={'text'}
          onChange={(event) => setValidCardNumber(event.valid)}
          className={'className'}
        />
      </CardInputBlock>
      <OtherInputs>
        <DateInputBlock>
          <Label>Expires on</Label>
          <Inputs>
            <MonthInputBlock>
              <CardMonthElement
                style={{
                  ...inputStyle,
                  placeholder: { content: 'MM', color: colors.gray },
                }}
                onChange={(event) => setValidCardMonth(event.valid)}
              />
            </MonthInputBlock>
            <YearInputBlock>
              <CardYearElement
                style={{
                  ...inputStyle,
                  placeholder: { content: 'YY', color: colors.gray },
                }}
                onChange={(event) => setValidCardYear(event.valid)}
              />
            </YearInputBlock>
          </Inputs>
        </DateInputBlock>
        <CvvInputBlock>
          <Label>CVC</Label>
          <CardCvvElement
            style={{
              ...inputStyle,
              placeholder: { content: '•••', color: colors.gray },
            }}
            onChange={(event) => setValidCardCvv(event.valid)}
          />
        </CvvInputBlock>
      </OtherInputs>
      <ButtonBlock>
        <PayButton
          disabled={
            !(
              isValidCardNumber &&
              isValidCardMonth &&
              isValidCardYear &&
              isValidCardCvv
            )
          }
          variant={'primary'}
          type={'submit'}
        >
          Place order
        </PayButton>
        {error ? <Error>{error}</Error> : null}
      </ButtonBlock>
      <Note>
        To confirm your card, we will charge $1 to that card. Once confirmed, we
        will immediately refund the charge. Depending on your bank, it can take
        several hours/days for the refund to appear on your card statement.
      </Note>
    </Form>
  );
}

export default PaymentForm;

const Form = styled.form``;
const NameInputs = styled.div`
  display: none;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const CardInputBlock = styled.div`
  margin-bottom: 20px;
`;

const DateInputBlock = styled.div``;

const Inputs = styled.div`
  display: flex;
`;

const MonthInputBlock = styled.div`
  margin-right: 8px;
  width: 120px;
  @media (max-width: 767px) {
    width: 90px;
  }
`;

const YearInputBlock = styled.div`
  width: 120px;
  @media (max-width: 767px) {
    width: 90px;
  }
`;

const CvvInputBlock = styled.div`
  width: 120px;
  @media (max-width: 767px) {
    width: 90px;
  }
`;

const OtherInputs = styled.div`
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
`;

const PayButton = styled(Button)`
  width: 100%;
  @media (max-width: 1023px) {
    font-size: 15px;
    line-height: 0;
    padding: 26px 40.5px;
  }
`;

const ButtonBlock = styled.div`
  position: relative;
`;

const Error = styled.div`
  position: absolute;
  color: red;
  left: 0;
  top: 100%;
  font-size: 12px;
`;

const Note = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${colors.grayDark2};
  margin-top: 20px;
  @media (max-width: 767px) {
    font-size: 10px;
    line-height: 14px;
    margin-top: 10px;
  } ;
`;
