import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as LogoSvg } from '../../../../assets/svg/logo.svg';
import { ReactComponent as WhiteLogoSvg } from '../../../../assets/svg/white-logo.svg';
import { ReactComponent as BurgerSvg } from '../../../../assets/svg/burger.svg';

import styled, { css } from 'styled-components';
import { colors } from '../../../../constants/theme';
import { useQuery } from '../../../../utils/common';
import { Link } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import { gtag } from '../../../../services/gtag';
import MobileMenu from './components/MobileMenu';

const MENU_LIST = [
  {
    name: 'Processes',
    link: 'processes',
  },
  {
    name: 'Reasons',
    link: 'reasons',
  },
  {
    name: 'Benefits',
    link: 'benefits',
  },
  {
    name: 'Contacts',
    link: 'contacts',
  },
];

function Header() {
  const query = useQuery();
  const history = useHistory();

  const getPlan = query.get('plan');
  const plan = getPlan ? getPlan : 'default';

  const [isVisible, setVisible] = useState(false);

  const headerRef = useRef<HTMLHeadElement>(null);
  const [isScroll, setScroll] = useState(false);

  useEffect(() => {
    function listener(this: Document, event: Event) {
      const INITIAL_OFFSET = 20;

      if (INITIAL_OFFSET < window.pageYOffset && !isScroll) {
        setScroll(true);
      }
      if (INITIAL_OFFSET >= window.pageYOffset && isScroll) {
        setScroll(false);
      }
    }

    document.addEventListener('scroll', listener);

    return () => document.removeEventListener('scroll', listener);
  }, [isScroll]);

  function handleButtonClick() {
    history.push({ pathname: '/form', search: query.toString() });
    gtag.event('navigate_to_form', {
      source_block: 'header',
      plan: plan,
    });
  }

  function handleScrollClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <Component ref={headerRef} isScroll={isScroll}>
      <ContentContainer>
        <Logo onClick={handleScrollClick} isScroll={isScroll}>
          <WhiteLogoIcon isScroll={isScroll} />
          <LogoIcon isScroll={isScroll} />
        </Logo>
        <MenuList>
          {MENU_LIST.map((menuItem) => (
            <Item key={menuItem.name}>
              <TextLink
                to={menuItem.link}
                smooth={true}
                duration={300}
                offset={-100}
                isScroll={isScroll}
                activeClass={'active'}
                spy={true}
              >
                {menuItem.name}
              </TextLink>
            </Item>
          ))}

          <Item>
            <ButtonLink onClick={handleButtonClick} isScroll={isScroll}>
              Get Your Card
            </ButtonLink>
          </Item>
        </MenuList>

        <BurgerIcon isScroll={isScroll} onClick={() => setVisible(true)} />
        <MobileMenu isVisible={isVisible} setVisible={setVisible} />
      </ContentContainer>
    </Component>
  );
}

export default Header;

const Component = styled.header<{ isScroll: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: 0.3s;
  ${(props) =>
    props.isScroll
      ? css`
          background-color: #fff;
          box-shadow: 0 10px 10px rgba(192, 188, 182, 0.05);
        `
      : null}
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 33px;
  @media (max-width: 1023px) {
    padding: 10px 20px;
  }
`;

const Logo = styled.div<{ isScroll: boolean }>`
  position: relative;
  width: 272px;
  height: 76px;
  transition: 0.3s;
  cursor: pointer;
  ${(props) =>
    props.isScroll
      ? css`
          height: 50px;
          width: 179px;
        `
      : null}

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 767px) {
    height: 50px;
    width: 179px;
  }
`;

const WhiteLogoIcon = styled(WhiteLogoSvg)<{ isScroll: boolean }>`
  transition: 0.3s;
  opacity: ${(props) => (props.isScroll ? 0 : 1)};
`;

const BurgerIcon = styled(BurgerSvg)<{ isScroll: boolean }>`
  transition: 0.3s;
  fill: ${(props) => (props.isScroll ? '#000' : '#fff')};
  display: none;
  @media (max-width: 767px) {
    display: inline-block;
  }
`;

const LogoIcon = styled(LogoSvg)<{ isScroll: boolean }>`
  transition: 0.3s;
  opacity: ${(props) => (props.isScroll ? 1 : 0)};
`;

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const Item = styled.li`
  padding-bottom: 10px;
  &:not(:last-child) {
    margin-right: 50px;
    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .active {
    border-bottom: 2px solid #28664040;
  }
`;

const TextLink = styled(Link)<{ isScroll: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  ${(props) =>
    props.isScroll
      ? css`
          color: #000;
        `
      : null}
  &:hover {
    border-bottom: 2px solid #28664040;
  }
`;

const ButtonLink = styled.button<{ isScroll: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.isScroll ? '#fff' : '#000')};
  background-color: ${(props) => (props.isScroll ? colors.green : '#fff')};
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s;
  padding: 12px 24px;

  &:hover {
    background-color: ${(props) =>
      props.isScroll ? colors.greenDark : '#ffffff80'};
  }
`;
