import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../../constants/theme';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const MultiSelectorCheckbox = forwardRef<HTMLInputElement, Props>(
  function Checkbox(props, ref) {
    const { checked, label, className, ...other } = props;

    return (
      <Label
        checked={Boolean(checked)}
        className={className}
        aria-label={String(label)}
      >
        {label && <LabelText checked={Boolean(checked)}>{label}</LabelText>}
        <Input
          checked={Boolean(checked)}
          type="checkbox"
          {...other}
          ref={ref}
        />
      </Label>
    );
  }
);

export default MultiSelectorCheckbox;

const Label = styled.label<{ checked: boolean }>`
  position: relative;
  display: inline-flex;
  cursor: pointer;
  background: #ffffff;
  border-radius: 38px;
  padding: 15px 26.3px;
  transition: 0.3s;
  &:hover {
    background-color: ${colors.greenLight3};
  }
  ${(props) =>
    props.checked
      ? css`
          background-color: ${colors.green};
          &:hover {
            background-color: ${colors.green};
          }
        `
      : null}
`;

const LabelText = styled.span<{ checked: boolean }>`
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 22px;
  ${(props) =>
    props.checked
      ? css`
          color: #fff;
        `
      : null}
`;

const Input = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;
