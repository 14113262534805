import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../../constants/theme';
import { useAppSelector } from '../../../../../store/hooks';
import { shallowEqual } from 'react-redux';
import Button from '../../../../../components/Button';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../../utils/common';
import { gtag } from '../../../../../services/gtag';

function RecommendedCard() {
  const query = useQuery();
  const history = useHistory();

  const getPlan = query.get('plan');
  const plan = getPlan ? getPlan : 'default';

  const { planData } = useAppSelector(
    (state) => ({
      planData: state.plan.data,
    }),
    shallowEqual
  );

  function handleButtonClick() {
    history.push({ pathname: '/form', search: query.toString() });
    gtag.event('navigate_to_form', {
      source_block: 'footer',
      plan: plan,
    });
  }

  return (
    <Component>
      <Title><span>M</span><span>M</span><span>J</span> recommendation letter </Title>
      <Description>
        We are a team of <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> health professionals who have been serving the
        people for over a decade . We can help you get the best possible care.
      </Description>
      <Price>${planData?.price}</Price>
      <ButtonLink onClick={handleButtonClick} variant={'primary'}>
        Get Your Card
      </ButtonLink>
    </Component>
  );
}

export default RecommendedCard;

const Component = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 30px rgba(17, 70, 38, 0.3);
  border-radius: 16px;
  text-align: center;
  padding: 40px;
  z-index: 1;
  position: relative;
  @media (max-width: 767px) {
    padding: 41px 20px 20px;
  }
`;

const Title = styled.h3`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.grayDark2};
  opacity: 0.7;
  margin-bottom: 20px;
`;

const Price = styled.p`
  font-family: 'Playfair Display', sans-serif;
  font-size: 62px;
  line-height: 83px;
  margin-bottom: 40px;
  @media (max-width: 1023px) {
    font-size: 36px;
    line-height: 48px;
  }
`;

const ButtonLink = styled(Button)`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  padding: 20px 0;
`;
