import styled, { css } from 'styled-components';

import { colors } from '../../constants/theme';

export type ReservedButtonVariant =
  // | 'secondary'
  | 'outlined'
  // | 'contained'
  | 'primary';

export type ButtonVariant = ReservedButtonVariant;

const variantCssMap: Record<ReservedButtonVariant, ReturnType<typeof css>> = {
  outlined: css`
    color: ${colors.grayDark2};
    border: 1px solid ${colors.grayLight};
    font-weight: 400;
    &:hover {
      border: 1px solid #d1d1d1;
    }
    &:disabled {
      background-color: ${colors.gray};
    }
  `,

  primary: css`
    background-color: ${colors.green};
    color: #ffff;
    font-weight: 600;

    &:hover {
      background-color: ${colors.greenDark};
    }
    &:disabled {
      opacity: 0.3;
      &:hover {
        background-color: ${colors.green};
      }
    }
  `,
};

export const StyledButton = styled.button<{ variant?: ButtonVariant }>`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  border-radius: 8px;
  transition: 0.3s;
  padding: 17px;
  width: 100%;
  display: inline-block;
  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};
  &:disabled {
    cursor: default;
  }
`;
