import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  text: string;
  icon: React.ReactNode;
};

function MedicalCard({ title, text, icon }: Props) {
  return (
    <Container>
      <IconBlock>{icon}</IconBlock>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 40px;
  border-radius: 16px;

  box-shadow: 0px 10px 10px rgba(192, 188, 182, 0.05);
  @media (max-width: 767px) {
    padding: 20px;
  }
`;

const IconBlock = styled.div`
  display: inline-block;
  margin-bottom: 30px;
  background: #f8f5f1;
  border-radius: 50%;
  padding: 30px;
  @media (max-width: 767px) {
    margin-bottom: 17px;
  }
`;

const Title = styled.span`
  display: block;
  font-family: 'Playfair Display', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const Text = styled.span`
  display: block;
  font-size: 16px;
  line-height: 26px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

export default MedicalCard;
