export interface OptionType<T = string> {
  value: T;
  label: string;
}

export interface PlanType {
  price: number;
  title: string;
}

export function canUseDOM() {
  return (
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined'
  );
}
