import React from 'react';

import styled from 'styled-components';
import { colors } from '../../../../../constants/theme';
import { ReactComponent as LogoSvg } from '../../../../../assets/svg/white-logo.svg';
import { ReactComponent as FacebookIcon } from '../../../../../assets/svg/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../../../assets/svg/instagram.svg';
import { ReactComponent as PinterestIcon } from '../../../../../assets/svg/pinterest.svg';
import { ReactComponent as TwitterIcon } from '../../../../../assets/svg/twitter.svg';
import { Element } from 'react-scroll';

const PHONE = '661-403-5506';
const EMAIL = 'support@mmjcardamericaonline.com';

function BottomFooter() {
  return (
    <Component>
      <Element name={'contacts'}>
        <Inner>
          <First>
            <Title><span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Card</Title>
            <Description>
              We are a team of <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> health professionals who have been
              serving the people for over a decade . We can help you get the
              best possible care.
            </Description>
            <LogoIcon />
            <Annotate>
              © 2021 mmjcardamericaonline. All rights reserved.
            </Annotate>
          </First>
          <Second>
            <Title>Our contacts</Title>
            <Address
              href={`https://goo.gl/maps/f6X7p5Ys1V34uos59`}
              target={`_blank`}
            >
              13061 Rosedale Hwy G, Bakersfield, CA 93314
            </Address>
            <Number href={`tel: ${PHONE}`}>{PHONE}</Number>
            <Email href={`mailto: ${EMAIL} `}>{EMAIL}</Email>
          </Second>
          <Third>
            <Title>Get in touch</Title>
            <SocialBlock>
              <Social href={'https://www.facebook.com/420formebakersfield/'}>
                <FacebookIcon />
              </Social>
              <Social href={'https://www.instagram.com/mmjbakersfield/'}>
                <InstagramIcon />
              </Social>
              <Social
                href={'https://www.pinterest.com/420formebakersfield/_saved/'}
              >
                <PinterestIcon />
              </Social>
              <Social href={'https://twitter.com/420bakersfield'}>
                <TwitterIcon />
              </Social>
            </SocialBlock>
          </Third>
        </Inner>
      </Element>
    </Component>
  );
}

export default BottomFooter;

const Component = styled.div`
  padding-top: 80px;
  @media (max-width: 1023px) {
    padding-top: 60px;
  }
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 6fr 3.8fr 2fr;
  column-gap: 54px;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const LogoIcon = styled(LogoSvg)`
  margin-bottom: 22px;
`;

const Title = styled.h4`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  margin-bottom: 30px;
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${colors.greenLight2};
  max-width: 495px;
  margin-bottom: 25px;
  @media (max-width: 1023px) {
    margin-bottom: 25px;
  }
`;

const Annotate = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.greenLight2};
  max-width: 495px;
`;

const First = styled.div``;

const Second = styled.div`
  @media (max-width: 1023px) {
    margin-bottom: 44px;
  }
`;

const Third = styled.div`
  @media (max-width: 1023px) {
    margin-bottom: 30px;
  }
`;

const Address = styled.a`
  margin-bottom: 7px;
  display: block;
  transition: 0.3s;
  color: ${colors.greenLight2};
  font-size: 16px;
  line-height: 22px;

  &:hover {
    color: #ffffff;
  }
  @media (max-width: 1023px) {
    margin-bottom: 14px;
  }
`;

const Number = styled.a`
  margin-bottom: 15px;
  display: block;
  transition: 0.3s;
  color: ${colors.greenLight2};
  font-size: 16px;
  line-height: 22px;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 1023px) {
    margin-bottom: 17px;
  }
`;

const Email = styled.a`
  margin-bottom: 15px;
  display: block;
  transition: 0.3s;
  color: ${colors.greenLight2};
  font-size: 16px;
  line-height: 22px;
  &:hover {
    color: #ffffff;
  }
`;

const SocialBlock = styled.div`
  margin-bottom: 15px;
  display: block;
  transition: 0.3s;
  @media (max-width: 1023px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 17px;
  }
`;

const Social = styled.a`
  display: block;
  transition: 0.3s;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  svg {
    transition: 0.3s;
    fill: ${colors.greenLight2};
    &:hover {
      fill: #fff;
    }
  }
`;
