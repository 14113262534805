import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Step1Icon } from '../../../../assets/svg/home/step-1.svg';
import { ReactComponent as Step2Icon } from '../../../../assets/svg/home/step-2.svg';
import { ReactComponent as Step3Icon } from '../../../../assets/svg/home/step-3.svg';
import HempImageUrl from '../../../../assets/images/hemp.png';
import HempMobileImageUrl from '../../../../assets/images/hemp-mobile.png';
import StepCard from './components/StepCard';
import ContentContainer from '../../ContentContainer';
import { useQuery } from '../../../../utils/common';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import { Element } from 'react-scroll';
import { gtag } from '../../../../services/gtag';
import { colors } from '../../../../constants/theme';

const STEPS = [
  {
    title: 'First Step',
    description:
      'Start by filing a simple form and enter all the details carefully. The information provided by the patient is protected under the HIPAA guidelines.',
    icon: <Step1Icon />,
  },
  {
    title: 'Second Step',
    description:
      'Have a face-to-face consultation with a state-licensed medical health physician through the telemedicine platform over your phone or desktop.',
    icon: <Step2Icon />,
  },
  {
    title: 'Third Step',
    description:
      'After qualification, receive a PDF copy of the recommendation via email and access marijuana from state-licensed dispensaries the same day.',
    icon: <Step3Icon />,
  },
];

function SecondSection() {
  const query = useQuery();
  const history = useHistory();

  const getPlan = query.get('plan');
  const plan = getPlan ? getPlan : 'default';

  function handleButtonClick() {
    history.push({ pathname: '/form', search: query.toString() });
    gtag.event('navigate_to_form', {
      source_block: 'reasons',
      plan: plan,
    });
  }

  return (
    <Component>
      <ContentContainer>
        <ProcessesBlock name={'processes'}>
          <Title>
            Receive Approval For Your <span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Recommendation with our
            simple processes
          </Title>
          <Steps>
            {STEPS.map((step) => (
              <StepCard
                key={step.title}
                icon={step.icon}
                title={step.title}
                description={step.description}
              />
            ))}
          </Steps>
        </ProcessesBlock>

        <Reasons name={'reasons'}>
          <Information>
            <ImageBlock>
              <Image src={HempImageUrl} />
              <ImageMobile src={HempMobileImageUrl} />
            </ImageBlock>
            <RightBlock>
              <InformationTitle>
                Why Is It Best to Get a <span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Card Online?
              </InformationTitle>
              <InformationDescription
                dangerouslySetInnerHTML={{
                  __html:
                    'The process of getting a <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>m</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> card can be quite intimidating for new patients, especially when new patients start Googling terms such as <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>d</span><span>o</span><span>c</span><span>t</span><span>o</span><span>r</span><span>s</span> near me. These terms may lead you to the right place, but not all <span>d</span><span>o</span><span>c</span><span>t</span><span>o</span><span>r</<span><span>s</span> are willing to provide a <span>c</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> recommendation.\n' +
                    '<br/> <br/>' +
                    'For a very simple reason, <span>c</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> is still illegal on the <span>f</span><span>e</span><span>d</span><span>e</span><span>r</span><span>a</span><span>l</span> <span>l</span><span>e</span><span>v</span><span>e</span><span>l</span>. However, Bakersfield has legalized <span>c</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> for both <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> and recreational purposes. So, patients seeking a <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>c</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> card can purchase <span>c</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> with the help of an <span>M</span><span>M</span><span>J</span> card legally. The only exception here is that it is easier, quick, and convenient to get an <span>M</span><span>M</span><span>J</span> card online.',
                }}
              />
              <ButtonLink variant={'primary'} onClick={handleButtonClick}>
                Get Your Card
              </ButtonLink>
            </RightBlock>
          </Information>
        </Reasons>
      </ContentContainer>
    </Component>
  );
}

export default SecondSection;

const Component = styled.section`
  padding-top: 140px;
  margin-bottom: 100px;
  @media (max-width: 767px) {
    padding-top: 60px;
    margin-bottom: 58px;
    overflow: hidden;
  }
`;

const ProcessesBlock = styled(Element)`
  margin-bottom: 100px;
  @media (max-width: 767px) {
    margin-bottom: 62px;
  }
`;

const Reasons = styled(Element)`
  @media (max-width: 767px) {
    padding-bottom: 80px;
    border-bottom: 1px solid ${colors.grayLight};
  }
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 400;
  line-height: 53px;
  margin-bottom: 80px;
  font-family: 'Playfair Display', sans-serif;
  max-width: 702px;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 60px;
  }
`;

const Steps = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 174px;
  @media (max-width: 1259px) {
    column-gap: 74px;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

const Information = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 23px;
  @media (max-width: 767px) {
    display: block;
  }
`;

const ImageBlock = styled.div`
  margin-left: -150px;
  max-width: 730px;
  max-height: 820px;
  @media (max-width: 1259px) {
    margin-left: -50px;
  }
  @media (max-width: 767px) {
    text-align: right;
  }
  @media (max-width: 374px) {
    margin-left: 0;
  }
`;

const Image = styled.img`
  @media (max-width: 767px) {
    display: none;
  }
`;

const ImageMobile = styled.img`
  display: none;
  max-width: unset;
  @media (max-width: 767px) {
    display: inline-block;
    margin-right: -20px;
  }
`;

const RightBlock = styled.div`
  padding-top: 148px;
  @media (max-width: 767px) {
    margin-top: -4px;
    padding-top: 0;
    //border-top: 1px solid #e4e1dd7d;
    z-index: 1;
    position: relative;
  }
`;

const InformationTitle = styled.h2`
  font-size: 40px;
  font-weight: 400;
  line-height: 53px;
  margin-bottom: 40px;
  font-family: 'Playfair Display', sans-serif;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 27px;
  }
`;

const InformationDescription = styled.p`
  font-size: 16px;
  line-height: 28px;
  opacity: 0.7;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-bottom: 33px;
  }
`;

const ButtonLink = styled(Button)`
  width: auto;
  padding: 17px 61px;
`;
