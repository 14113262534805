import React from 'react';
import styled, { keyframes } from 'styled-components';

type OverlayProps = {
  className?: string;
  size?: number;
  color?: string;
  withoutBg?: boolean;
};

function Loader({
  className,
  size = 60,
  color = '#8b29ff',
  withoutBg = false,
}: OverlayProps) {
  return (
    <OverlayBlock className={className} withoutBg={withoutBg}>
      <Container>
        <Circle color={color} size={size} />
      </Container>
    </OverlayBlock>
  );
}
const spin = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`;

const Circle = styled.div<{ color: string; size: number }>`
  display: block;
  width: ${(props) => (props.size ? props.size : 0)}px;
  height: ${(props) => (props.size ? props.size : 0)}px;
  margin: 0 auto;
  border: 2px solid #f5f5f5;
  border-top: 3px solid ${(props) => (props.color ? props.color : null)};
  border-radius: 50%;
  animation: ${spin} 1s infinite linear;
`;

const Container = styled.div`
  z-index: 5;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const OverlayBlock = styled.div<{ withoutBg: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) =>
    props.withoutBg ? null : 'rgba(255, 255, 255, 0.7)'};
  z-index: 10;
`;

export default Loader;
