import React from 'react';
import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { useFormikContext } from 'formik';
import Selector from '../../../components/Selector';
import { Values } from '../FormPage';
import { colors } from '../../../constants/theme';
import MultiSelectorCheckbox from '../../../components/MultiSelectorCheckbox';
import DropdownMultipleSelect from '../../../components/DropdownMultipleSelect';
import moment from 'moment';
import DropDownSelect from '../../../components/DropDownSelect';
import { useAppSelector } from '../../../store/hooks';
import { shallowEqual } from 'react-redux';
import AutoWidthTextField from '../../../components/AutoWidthTextField';
import { Element } from 'react-scroll';
import { validateOnlyNumberAndLength } from '../formPage.helpers';
import useMedia from '../../../hooks/useMedia';

const STATE = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

function PersonalInfo() {
  const {
    setFieldValue,
    values,
    handleChange,
    errors,
    touched,
  } = useFormikContext<Values>();

  const isMobile = useMedia('(max-width: 767px)');

  const { conditionsData } = useAppSelector(
    (state) => ({
      conditionsData: state.conditions.data,
    }),
    shallowEqual
  );

  function handleSetGenderFieldChange(value: string) {
    setFieldValue('gender', value);
  }

  const months = moment.months();

  function generateDayArray(length: number): Array<string> {
    return Array.from({ length }, (_, index) => {
      const day = String(index + 1);
      if (day.length === 1) {
        return '0' + day;
      }
      return day;
    });
  }

  function generateYearsArray(year: number): Array<string> {
    const years = [];
    const nowYear = moment().year() - 18;

    for (let max = nowYear; max > year; max--) {
      years.push(String(max));
    }

    return years;
  }

  function getMonthsOption() {
    return months.map((month) => ({
      value: moment().month(month).format('MM'),
      label: moment().month(month).format('MM'),
    }));
  }

  function getDaysOption() {
    return generateDayArray(31).map((day) => ({
      value: day,
      label: day,
    }));
  }

  function getYearsOption() {
    return generateYearsArray(1950).map((year) => ({
      value: year,
      label: year,
    }));
  }

  function isCheckedQualifyingCondition(qualifyingCondition: string) {
    return !!values.qualifyingConditions.find(
      (currentQualifyingCondition) =>
        currentQualifyingCondition === qualifyingCondition
    );
  }

  function handleQualifyingConditions(value: Array<string>) {
    setFieldValue('qualifyingConditions', value);
  }

  function handleSetMonthFieldChange(value: string) {
    setFieldValue('date', `${values.year}-${value}-${values.day}`);
    setFieldValue('month', value);
  }

  function handleSetDayFieldChange(value: string) {
    setFieldValue('date', `${values.year}-${values.month}-${value}`);
    setFieldValue('day', value);
  }

  function handleSetYearFieldChange(value: string) {
    setFieldValue('date', `${value}-${values.month}-${values.day}`);
    setFieldValue('year', value);
  }

  function handleWeightChange(event: React.ChangeEvent<HTMLInputElement>) {
    validateOnlyNumberAndLength(event, handleChange, 3);
  }

  function handleHeightChange(event: React.ChangeEvent<HTMLInputElement>) {
    validateOnlyNumberAndLength(event, handleChange, 3);
  }

  return (
    <Component>
      <FirstBlock>
        <Element name={'date'}>
          <DateBlock>
            {errors.date && touched.date ? (
              <HelperText>{errors.date}</HelperText>
            ) : null}
            <Label>Date of birth</Label>
            <Date>
              <Element name={'month'}>
                <DropDownSelect
                  onChange={handleSetMonthFieldChange}
                  value={values.month}
                  options={getMonthsOption()}
                  placeholder={'MM'}
                  helperText={errors.month && touched.month ? errors.month : ''}
                />
              </Element>
              <Element name={'day'}>
                <DropDownSelect
                  onChange={handleSetDayFieldChange}
                  value={values.day}
                  options={getDaysOption()}
                  placeholder={'DD'}
                  helperText={errors.day && touched.day ? errors.day : ''}
                />
              </Element>
              <Element name={'year'}>
                <DropDownSelect
                  onChange={handleSetYearFieldChange}
                  value={values.year}
                  options={getYearsOption()}
                  placeholder={'YYYY'}
                  helperText={errors.year && touched.year ? errors.year : ''}
                />
              </Element>
            </Date>
          </DateBlock>
        </Element>
        <Selector
          value={values.gender}
          onChange={handleSetGenderFieldChange}
          options={STATE}
          label={'Gender'}
        />
      </FirstBlock>
      <SecondBlock>
        <AutoWidthTextField
          name={'height'}
          value={values.height}
          onChange={handleHeightChange}
          label={'Height'}
          placeholder={
            isMobile ? 'Height (inch)' : 'What’s your height? (inch)'
          }
          postfix={'inch'}
          helperText={errors.height && touched.height ? errors.height : ''}
        />
        <AutoWidthTextField
          name={'weight'}
          value={values.weight}
          onChange={handleWeightChange}
          label={'Weight'}
          placeholder={isMobile ? 'Weight (inch)' : 'What’s your weight? (lbs)'}
          helperText={errors.weight && touched.weight ? errors.weight : ''}
          postfix={'lbs'}
        />
      </SecondBlock>
      <Input
        name={'allergies'}
        value={values.allergies}
        onChange={handleChange}
        label={'Allergies'}
        placeholder={'What are your allergies? (If any)'}
        helperText={
          errors.allergies && touched.allergies ? errors.allergies : ''
        }
      />
      <Input
        name={'medical'}
        value={values.medical}
        onChange={handleChange}
        label={'Medical condition'}
        placeholder={
          isMobile
            ? 'What you want to use cannabis for?'
            : 'What medical condition you want to use cannabis for?'
        }
        helperText={errors.medical && touched.medical ? errors.medical : ''}
      />
      <Label>Qualifying conditions</Label>
      <QualifyingConditions>
        <Description>
          Check each <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> problem that you suffer from or select other to
          have the <span>d</span><span>o</span><span>c</span><span>t</span><span>o</span><span>r</span> evaluate your unique condition. At least one box must
          be checked to qualify.
        </Description>
        <MultiSelector>
          {conditionsData.map((disease) => (
            <Checkbox
              name={'qualifyingConditions'}
              value={disease.value}
              checked={isCheckedQualifyingCondition(disease.value)}
              label={disease.label}
              onChange={handleChange}
              key={disease.value}
            />
          ))}
        </MultiSelector>
        <MobileSelect>
          <DropdownMultipleSelect
            options={conditionsData}
            value={values.qualifyingConditions}
            onChange={handleQualifyingConditions}
            placeholder={'Check your medical problems'}
          />
        </MobileSelect>
      </QualifyingConditions>
    </Component>
  );
}

export default PersonalInfo;

const Component = styled.div``;

const Input = styled(TextField)`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const FirstBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    display: block;
    margin-bottom: 20px;
  }
`;

const DateBlock = styled.div`
  position: relative;
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 20px;
  }
`;

const Date = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
`;

const SecondBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const QualifyingConditions = styled.div`
  background-color: ${colors.grayLight2};
  border-radius: 12px;
  padding: 40px 30px 30px;

  @media (max-width: 767px) {
    background-color: unset;
    padding: 0;
    border-radius: 0;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MultiSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileSelect = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  } ;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const Checkbox = styled(MultiSelectorCheckbox)`
  margin-bottom: 10px;
  margin-right: 10px;
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  left: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  top: -30px;
  white-space: nowrap;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 10px;
    border-top: 5px solid ${colors.red};
  }
`;
