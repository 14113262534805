import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OptionType } from '../typings/model';

import { AppDispatch } from './store';
import { getConditions } from '../services/requests';

type State = {
  status: 'idle' | 'pending' | 'success' | 'failed';
  data: Array<OptionType>;
  error: string | null;
};

const initialState: State = {
  status: 'idle',
  data: [],
  error: null,
};

const slice = createSlice({
  name: 'condition',
  initialState,
  reducers: {
    fetchConditionLoading: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    fetchConditionSuccess: (
      state,
      action: PayloadAction<Array<OptionType>>
    ) => {
      state.status = 'success';
      state.data = action.payload;
    },
    fetchConditionError: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.data = [];
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

const {
  fetchConditionLoading,
  fetchConditionSuccess,
  fetchConditionError,
} = slice.actions;

export function fetchConditionThunk(): (
  dispatch: AppDispatch
) => Promise<Array<OptionType>> {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchConditionLoading());
    try {
      const response = await getConditions();

      const optionResponse = response.data.map((condition) => ({
        value: condition.name,
        label: condition.name,
      }));

      dispatch(fetchConditionSuccess(optionResponse));
      return optionResponse;
    } catch (error) {
      dispatch(fetchConditionError(error.message));
      return [];
    }
  };
}
