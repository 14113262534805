import React, { useMemo } from 'react';
import { useSelect } from 'downshift';
import { OptionType } from '../../typings/model';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';
import { ReactComponent as ArrowDownSvg } from '../../assets/svg/arrow-down.svg';

interface Props {
  options: Array<OptionType>;
  value: string;
  onChange: (selectedOption: string) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  helperText?: string;
}

function DropDownSelect({
  options,
  placeholder,
  label,
  value,
  onChange,
  className,
  helperText,
}: Props) {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    onSelectedItemChange: (changes) => {
      onChange(changes.selectedItem?.value ?? '');
    },
  });

  const currentOptionLabel = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  return (
    <DropDown className={className}>
      {label ? <Label>{label}</Label> : null}
      <DropDownDisplay
        type="button"
        isOpen={isOpen}
        invalid={!!helperText}
        {...getToggleButtonProps()}
      >
        <DropDownDisplayText isSelected={!!value}>
          {currentOptionLabel?.label || placeholder || 'DropDownSelect...'}
        </DropDownDisplayText>
        <DropDownDisplayIcon />
        {helperText ? <HelperText>{helperText}</HelperText> : null}
      </DropDownDisplay>

      <OptionList {...getMenuProps()}>
        {isOpen &&
          options.map((option, index) => (
            <Item
              isHighlighted={highlightedIndex === index}
              key={`${option.value}${index}`}
              {...getItemProps({ item: option, index })}
            >
              {option.label}
            </Item>
          ))}
      </OptionList>
    </DropDown>
  );
}

export default DropDownSelect;

const DropDown = styled.div`
  position: relative;
`;

const DropDownDisplayIcon = styled(ArrowDownSvg)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  fill: #9f9f9f;
  transition: transform 0.3s ease 0s;
`;

const DropDownDisplay = styled.button<{ isOpen: boolean; invalid: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  padding: 18.5px 20px;
  background: #fff;
  border: 1px solid
    ${(props) =>
      props.invalid
        ? colors.red
        : props.isOpen
        ? colors.grayDark
        : colors.grayLight};

  border-radius: 8px;
  line-height: 15px;
  color: #9f9f9f;
  outline: none;
  transition: 0.3s;
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${DropDownDisplayIcon} {
        transform: translateY(-50%) rotate(180deg);
      }
    `}
  &:hover {
    border-color: ${colors.grayDark};
  }
`;

const DropDownDisplayText = styled.span<{ isSelected: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: ${(props) => (props.isSelected ? '#000' : colors.gray)};
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const OptionList = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  background-color: #fff;
  z-index: 1;
  max-height: 424px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: auto;
`;

const Item = styled.li<{ isHighlighted: boolean }>`
  width: 100%;
  padding: 17px 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  ${(props) =>
    props.isHighlighted
      ? css`
          background-color: ${colors.grayLight2};
        `
      : null}
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  top: -36px;
  right: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    right: 10px;
    border-top: 5px solid ${colors.red};
  }
`;
