import React, { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { OptionType } from '../../typings/model';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';
import TextInput from '../TextInput';
import { ReactComponent as ArrowDownSvg } from '../../assets/svg/arrow-down.svg';

interface Props {
  options: Array<OptionType>;
  value: string;
  onChange: (selectedOption: string) => void;
  placeholder?: string;
  label?: string;
  className?: string;
  helperText?: string;
  invalid?: boolean;
}

function DropDownCombobox({
  options,
  placeholder,
  label,
  value,
  onChange,
  className,
  helperText,
  invalid,
}: Props) {
  const optionLabelList = options.map((option) => option.label);
  const [inputOptions, setInputOptions] = useState<Array<string>>([]);

  useEffect(() => {
    setInputOptions(optionLabelList);
  }, [options]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    getComboboxProps,
    openMenu,
  } = useCombobox({
    // selectedItem: optionLabelList.find((option) => option === value),
    items: optionLabelList,
    onSelectedItemChange: (changes) => {
      const selectedOption = options.find(
        (option) => option.label === changes.selectedItem
      );
      onChange(selectedOption?.value ?? '');
    },

    onInputValueChange: ({ inputValue }) => {
      setInputOptions(
        optionLabelList.filter((option) =>
          option.toLowerCase().startsWith(inputValue?.toLowerCase() ?? '')
        )
      );
    },
  });

  return (
    <DropDown className={className} {...getComboboxProps()}>
      {label ? <Label>{label}</Label> : null}
      <InputBlock>
        <TextInput
          placeholder={placeholder}
          {...getInputProps()}
          autoComplete={'none'}
          onClick={openMenu}
          invalid={invalid ? invalid : !!helperText}
        />
        <DropDownDisplayIcon isOpen={isOpen} />
      </InputBlock>
      <OptionList {...getMenuProps()}>
        {isOpen &&
          inputOptions.map((option, index) => (
            <Item
              isHighlighted={highlightedIndex === index}
              key={`${option}${index}`}
              {...getItemProps({ item: option, index })}
            >
              {option}
            </Item>
          ))}
      </OptionList>
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </DropDown>
  );
}

export default DropDownCombobox;

const DropDown = styled.div`
  position: relative;
`;

const DropDownDisplayIcon = styled(ArrowDownSvg)<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: ${(props) =>
    props.isOpen ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'};
  fill: #9f9f9f;
  transition: transform 0.3s ease 0s;
`;

const InputBlock = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const OptionList = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  background-color: #fff;
  z-index: 1;
  max-height: 424px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: auto;
`;

const Item = styled.li<{ isHighlighted: boolean }>`
  width: 100%;
  padding: 17px 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  ${(props) =>
    props.isHighlighted
      ? css`
          background-color: ${colors.grayLight2};
        `
      : null}
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  top: -5px;
  right: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    right: 10px;
    border-top: 5px solid ${colors.red};
  }
`;
