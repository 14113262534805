import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import FormPage from './modules/FormPage';
import Home from './modules/Home';
import useFixedVhProperty from './hooks/useFixedVhProperty';
import { ModalProvider } from './components/ModalProvider';
import { gtagManager } from './services/gtag';
import { fetchStatesThunk } from './store/states';
import { fetchConditionThunk } from './store/conditions';
import { useAppDispatch } from './store/hooks';

function App() {
  const dispatch = useAppDispatch();

  useFixedVhProperty();

  useEffect(() => {
    gtagManager.init('G-5244661T69');
    dispatch(fetchStatesThunk());
    dispatch(fetchConditionThunk());
  }, []);

  return (
    <div className="App">
      <ModalProvider>
        <Router>
          <Switch>
            <Route path="/form">
              <FormPage />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ModalProvider>
    </div>
  );
}

export default App;
