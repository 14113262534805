import styled from 'styled-components';
import { colors } from '../../constants/theme';

export const Container = styled.div`
  position: relative;
`;

function getBorderColor(
  props: { invalid?: boolean; active?: boolean } = {}
): string {
  return props.invalid
    ? colors.red
    : props.active
    ? colors.grayDark
    : colors.grayLight;
}

export const Input = styled.input<{
  invalid?: boolean;
}>`
  display: block;
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  background-color: #fff;
  border: 1px solid ${(props) => getBorderColor({ invalid: props.invalid })};
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  // Placeholder
  &::placeholder {
    color: ${colors.gray};

    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled {
    background-color: ${colors.grayLight2};
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &:not([readonly]):focus {
    color: #24292e;
    background-color: #fff;
    border-color: ${(props) =>
      getBorderColor({ invalid: props.invalid, active: !props.readOnly })};
    outline: 0;
  }
  &[type='file'] {
    opacity: 0; /* make transparent */
    z-index: -1; /* move under anything else */
    position: absolute; /* don't let it take up space */
  }
  &:not([readonly]):hover {
    border-color: ${colors.grayDark};
  }
`;
