import React from 'react';
import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { useFormikContext } from 'formik';
import { Values } from '../FormPage';
import { useAppSelector } from '../../../store/hooks';
import { shallowEqual } from 'react-redux';
import { Element } from 'react-scroll';
import DropDownCombobox from '../../../components/DropDownCombobox';

function Address() {
  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormikContext<Values>();

  const { stateOptionList } = useAppSelector(
    (state) => ({
      stateOptionList: state.states.data,
    }),
    shallowEqual
  );

  function handleSetStateFieldChange(value: string) {
    setFieldValue('state', value);
  }

  function validateZipNumber(event: React.ChangeEvent<HTMLInputElement>) {
    const reg = new RegExp('^([\\d-]*)$');
    const value = event.target.value;

    if (value.length <= 13 && reg.test(value)) {
      handleChange(event);
    }
  }

  return (
    <Component>
      <FirstBlock>
        <Element name={'state'}>
          <DropDown
            value={values.state}
            onChange={handleSetStateFieldChange}
            options={stateOptionList}
            label={'State'}
            placeholder={'Choose your state'}
            helperText={errors.state && touched.state ? errors.state : ''}
          />
        </Element>
        <Element name={'zip'}>
          <Input
            name={'zip'}
            value={values.zip}
            onChange={validateZipNumber}
            label={'Zip'}
            placeholder={'What’s your zip code?'}
            helperText={errors.zip && touched.zip ? errors.zip : ''}
          />
        </Element>
      </FirstBlock>
      <SecondBlock>
        <Element name={'city'}>
          <Input
            name={'city'}
            value={values.city}
            onChange={handleChange}
            label={'City'}
            placeholder={'Where do you live?'}
            helperText={errors.city && touched.city ? errors.city : ''}
          />
        </Element>
        <Element name={'address'}>
          <Input
            name={'address'}
            value={values.address}
            onChange={handleChange}
            label={'Address'}
            placeholder={'Include apartment / suite number if any'}
            helperText={errors.address && touched.address ? errors.address : ''}
          />
        </Element>
      </SecondBlock>
    </Component>
  );
}
export default Address;

const Component = styled.div``;

const Input = styled(TextField)`
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const DropDown = styled(DropDownCombobox)`
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const FirstBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    display: block;
    margin-bottom: unset;
  }
`;

const SecondBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  @media (max-width: 767px) {
    display: block;
  }
`;
