import React, { useEffect } from 'react';
import styled from 'styled-components';
import { lock } from 'tua-body-scroll-lock';

import { ReactComponent as SuccessIcon } from '../../../assets/svg/success.svg';

import { colors } from '../../../constants/theme';
import Button from '../../Button';

function SuccessPaymentModal() {
  useEffect(() => {
    lock();
  }, []);

  function handleButtonClick() {
    document.location.replace('/');
  }

  return (
    <Inner>
      <SuccessBlock>
        <SuccessIcon />
      </SuccessBlock>
      <Title>Thank you!</Title>
      <Description>
        Our managers will soon process your application and call you back
        to confirm the information provided.
      </Description>
      <Button onClick={handleButtonClick} variant={'primary'}>
        Got it
      </Button>
    </Inner>
  );
}

export default SuccessPaymentModal;

const Inner = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 11;
  max-width: 472px;
  border-radius: 16px;
  padding: 40px;
  overflow: hidden;
  text-align: center;
  @media (max-width: 767px) {
    padding: 40px 20px;
    max-width: 335px;
  }
`;

const SuccessBlock = styled.div`
  display: inline-block;
  padding: 36px;
  border-radius: 50%;
  background: ${colors.bodily};
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-weight: 400;
  font-family: 'Playfair Display', sans-serif;
  font-size: 40px;
  line-height: 53px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 10px;
  } ;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 24px;
  } ;
`;
