import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OptionType } from '../typings/model';

import { AppDispatch } from './store';
import { getStates } from '../services/requests';

type States = {
  status: 'idle' | 'pending' | 'success' | 'failed';
  data: Array<OptionType>;
  error: string | null;
};

const initialState: States = {
  status: 'idle',
  data: [],
  error: null,
};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    fetchStatesLoading: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    fetchStatesSuccess: (state, action: PayloadAction<Array<OptionType>>) => {
      state.status = 'success';
      state.data = action.payload;
    },
    fetchStatesError: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.data = [];
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

const {
  fetchStatesLoading,
  fetchStatesSuccess,
  fetchStatesError,
} = slice.actions;

export function fetchStatesThunk(): (
  dispatch: AppDispatch
) => Promise<Array<OptionType>> {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchStatesLoading());
    try {
      const response = await getStates();

      const optionResponse = response.data.map((state) => ({
        value: state.abr,
        label: `${state.name} (${state.abr})`,
      }));

      dispatch(fetchStatesSuccess(optionResponse));
      return optionResponse;
    } catch (error) {
      dispatch(fetchStatesError(error.message));
      return [];
    }
  };
}
