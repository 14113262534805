import styled from 'styled-components';

const ContentContainer = styled.div`
  max-width: 1280px;
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  padding: 0 33px;
  @media (max-width: 1023px) {
    padding: 0 20px;
  }
`;
export default ContentContainer;
