import React from 'react';
import { OptionType } from '../../typings/model';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';

interface Props {
  options: Array<OptionType>;
  value: string;
  onChange: (selectedOption: string) => void;
  label?: string;
}

function Selector({ options, label, value, onChange }: Props) {
  function handleItemClick(option: OptionType) {
    onChange(option.value);
  }

  return (
    <DropDown>
      <Label>{label}</Label>
      <OptionList>
        {options.map((option, index) => (
          <Item
            isActive={value === option.value}
            key={`${option.value}${index}`}
            onClick={() => handleItemClick(option)}
          >
            {option.label}
          </Item>
        ))}
      </OptionList>
    </DropDown>
  );
}

export default Selector;

const DropDown = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const OptionList = styled.ul`
  width: 100%;
  background-color: ${colors.grayLight2};
  z-index: 1;
  display: flex;
  text-align: center;
  border-radius: 8px;
  overflow: auto;
  padding: 3px;
`;

const Item = styled.li<{ isActive: boolean }>`
  width: 100%;
  padding: 13px 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.grayDark2};
  transition: 0.3s;
  border-radius: 5px;

  ${(props) =>
    props.isActive
      ? css`
          background-color: #fff;
          color: #000;
        `
      : null}

  &:hover {
    color: #000;
  }
`;
