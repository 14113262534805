import { AxiosResponse } from 'axios';

import { request } from './api';
import { PlanType } from '../typings/model';

type valuesOptions = {
  [key: string]: File | null | string | Array<string> | boolean;
};

export function sendForm(
  values: valuesOptions
): Promise<AxiosResponse<{ client_id: string }>> {
  const bodyFormData = new FormData();

  for (const prop in values) {
    if (typeof values[prop] === 'boolean') {
      continue;
    }
    if (Array.isArray(values[prop])) {
      bodyFormData.append(prop, (values[prop] as Array<string>).join());
    } else {
      bodyFormData.append(prop, values[prop] as File | string);
    }
  }

  return request.post('/processUserData', bodyFormData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getPlanDetail(plan: string): Promise<AxiosResponse<PlanType>> {
  return request.get('/getPlanDetails', { params: { plan } });
}

export function purchaseApplicationPayment(
  application_id: string,
  token: string,
  plan_id: string
): Promise<AxiosResponse<{ result: string; sum: number }>> {
  return request.post('/purchaseApplicationPayment', {
    application_id,
    token,
    plan_id,
  });
}

export function getConditions(): Promise<
  AxiosResponse<Array<{ name: string }>>
> {
  return request.get(
    '/v0/b/mmjcardamericaonline-57186.appspot.com/o/qualifying_conditions.json',
    {
      params: { alt: 'media', token: 'ebda3504-5ffe-4e53-bfc4-69a2cf111931' },
      baseURL: 'https://firebasestorage.googleapis.com/',
    }
  );
}

export function getStates(): Promise<
  AxiosResponse<Array<{ name: string; abr: string }>>
> {
  return request.get(
    '/v0/b/mmjcardamericaonline-57186.appspot.com/o/states.json',
    {
      params: { alt: 'media', token: '913f9deb-5ec7-400d-ad9d-1094f2bb8c4d' },
      baseURL: 'https://firebasestorage.googleapis.com/',
    }
  );
}
