import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as CloseSvg } from '../../../../../assets/svg/close.svg';
import { ReactComponent as LogoSvg } from '../../../../../assets/svg/logo.svg';

import { colors } from '../../../../../constants/theme';
import { Link } from 'react-scroll';
import { gtag } from '../../../../../services/gtag';
import { useQuery } from '../../../../../utils/common';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/Button';
import { lock, unlock } from 'tua-body-scroll-lock';

interface Props {
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
}

const MENU_LIST = [
  {
    name: 'Processes',
    link: 'processes',
  },
  {
    name: 'Reasons',
    link: 'reasons',
  },
  {
    name: 'Benefits',
    link: 'benefits',
  },
  {
    name: 'Contacts',
    link: 'contacts',
  },
];

function MobileMenu({ isVisible, setVisible }: Props) {
  const query = useQuery();
  const history = useHistory();

  const getPlan = query.get('plan');
  const plan = getPlan ? getPlan : 'default';

  const mainRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isVisible) {
      lock();
    } else {
      unlock();
    }
  }, [isVisible]);

  function closeMenu() {
    setVisible(false);
  }

  function handleButtonClick() {
    history.push({ pathname: '/form', search: query.toString() });
    gtag.event('navigate_to_form', {
      source_block: 'header',
      plan: plan,
    });
  }

  return (
    <Container isVisible={isVisible}>
      <Overlay isVisible={isVisible} onClick={closeMenu} />
      <Inner isVisible={isVisible}>
        <Header>
          <LogoIcon />
          <CloseIcon onClick={closeMenu} />
        </Header>
        <Main ref={mainRef}>
          <FormLinkBlock>
            <Title><span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Card</Title>
            <Description>
              We are a team of <span>m</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> health professionals who have been
              serving the people for over a decade . We can help you get the
              best possible care.
            </Description>
            <Button variant={'primary'} onClick={handleButtonClick}>
              Get Your Card
            </Button>
          </FormLinkBlock>
          <MenuList>
            {MENU_LIST.map((menuItem) => (
              <Item key={menuItem.name}>
                <TextLink
                  to={menuItem.link}
                  smooth={true}
                  duration={300}
                  offset={-100}
                  spy={true}
                  onClick={closeMenu}
                >
                  {menuItem.name}
                </TextLink>
              </Item>
            ))}
          </MenuList>
        </Main>
      </Inner>
    </Container>
  );
}

export default MobileMenu;

const Container = styled.div<{ isVisible: boolean }>`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s cubic-bezier(0, 0, 0.2, 1) 0.3s,
    visibility 0s cubic-bezier(0, 0, 0.2, 1) 0.3s;
  position: absolute;

  z-index: 100000;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}
`;

const Overlay = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 0.6 : 0)};
  transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
  top: 0;
  position: absolute;
  will-change: opacity;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #000000;
  -webkit-tap-highlight-color: transparent;
`;

const Inner = styled.div<{ isVisible: boolean }>`
  position: fixed;
  background-color: #fff;
  flex-direction: column;
  height: 100%;
  right: 0;
  top: 0;
  transform: translateX(${({ isVisible }) => (isVisible ? 0 : 100)}%);
  display: flex;
  width: 100vw;
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
  z-index: 999;
  -webkit-overflow-scrolling: touch;
  box-shadow: -4px 0 8px 0 rgba(61, 47, 155, 0.06);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid ${colors.grayLight};
  margin-bottom: 30px;
  align-items: center;
`;

const Main = styled.div`
  padding: 0 20px;
  text-align: center;
  overflow: auto;
`;

const FormLinkBlock = styled.div`
  border-bottom: 1px solid ${colors.grayLight};
  padding-bottom: 40px;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 20px;
  font-family: 'Playfair Display', sans-serif;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
`;

const CloseIcon = styled(CloseSvg)`
  cursor: pointer;
`;

const LogoIcon = styled(LogoSvg)`
  width: 179px;
  height: 50px;
`;

const MenuList = styled.ul``;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 38px;
  }
`;

const TextLink = styled(Link)`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  font-family: 'Playfair Display', sans-serif;
  color: #000;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
`;
