import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import SignaturePad from 'signature_pad';
import Button from '../../../../components/Button';
import { useFormikContext } from 'formik';
import { Values } from '../../FormPage';
import { colors } from '../../../../constants/theme';
import useMedia from '../../../../hooks/useMedia';

function Signature() {
  const {
    setFieldValue,
    values,
    touched,
    errors,
    setFieldError,
  } = useFormikContext<Values>();

  const isMobile = useMedia('(max-width: 767px)');

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePad = useRef<SignaturePad | null>(null);

  const [successText, setSuccessText] = useState('');

  function resizeCanvas() {
    if (!canvasRef.current) return null;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
    canvasRef.current.height = canvasRef.current.offsetHeight * ratio;
    canvasRef.current.getContext('2d')?.scale(ratio, ratio);
    signaturePad.current?.clear(); // otherwise isEmpty() might return incorrect value
  }
  useEffect(() => {
    if (canvasRef.current) {
      signaturePad.current = new SignaturePad(canvasRef.current);
      window.addEventListener('orientationchange', resizeCanvas);
      resizeCanvas();
    }
  }, [canvasRef]);

  function handleSubmitSignatureClick() {
    if (!signaturePad.current) return null;

    if (signaturePad.current.isEmpty()) return null;

    const data = signaturePad.current.toDataURL('image/png');
    setFieldValue('signature', data);
    setSuccessText('Signature submited');
    signaturePad.current.off();
  }

  function handleClearSignatureClick() {
    if (!signaturePad.current) return null;
    signaturePad.current.clear();
    setFieldValue('signature', '');
    signaturePad.current.on();
    setSuccessText('');
  }

  function handleCanvasClick() {
    setFieldError('signature', undefined);
    touched.signature = false;
  }

  return (
    <Component>
      <Canvas
        ref={canvasRef}
        invalid={!!touched.signature && !!errors.signature}
        onClick={handleCanvasClick}
      />
      {touched.signature && errors.signature ? (
        <HelpersError>{errors.signature}</HelpersError>
      ) : null}
      {successText ? <HelpersSuccess>{successText}</HelpersSuccess> : null}
      <ButtonsBlock>
        <Button
          disabled={!!values.signature}
          variant={'primary'}
          onClick={handleSubmitSignatureClick}
        >
          {isMobile ? 'Submit Sign' : 'Submit Signature'}
        </Button>
        <Button variant={'outlined'} onClick={handleClearSignatureClick}>
          {isMobile ? 'Clear Sign' : 'Clear Signature'}
        </Button>
      </ButtonsBlock>
    </Component>
  );
}

export default Signature;

const Component = styled.div`
  border-bottom: 1px solid ${colors.grayLight};
  padding-bottom: 40px;
  margin-bottom: 40px;
  position: relative;
  @media (max-width: 767px) {
    border-bottom: unset;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const Canvas = styled.canvas<{ invalid: boolean }>`
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 150px;

  ${(props) =>
    props.invalid
      ? css`
          border: 1px solid ${colors.red};
        `
      : null};

  @media (max-width: 767px) {
    height: 220px;
  }
`;

const ButtonsBlock = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
`;

const Helpers = styled.div`
  display: inline-block;
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  top: 8px;
  right: 8px;
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
`;

const HelpersError = styled(Helpers)`
  background-color: ${colors.red};
`;

const HelpersSuccess = styled(Helpers)`
  background-color: ${colors.green};
`;
