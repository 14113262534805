import React from 'react';
import styled from 'styled-components';
import TextInput from '../TextInput';
import { colors } from '../../constants/theme';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  helperText?: string;
  className?: string;
}

function TextField({
  value,
  onChange,
  label,
  invalid,
  helperText,
  placeholder,
  className,
  ...other
}: Props) {
  return (
    <Component className={className}>
      {label ? <Label>{label}</Label> : null}
      <TextInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        invalid={invalid ? invalid : !!helperText}
        {...other}
      />
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </Component>
  );
}

export default TextField;

const Component = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  top: -5px;
  right: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    right: 10px;
    border-top: 5px solid ${colors.red};
  }
`;
