import React from 'react';
import styled from 'styled-components';
import WaveSvgUrl from '../../../../../assets/svg/wave.svg';
import WaveMobileSvgUrl from '../../../../../assets/svg/wave-mobile.svg';

interface Props {
  icon: React.ReactNode;
  title: string;
  description: string;
}

function StepCard({ title, description, icon }: Props) {
  return (
    <Component>
      <ImageBlock>{icon}</ImageBlock>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Component>
  );
}

export default StepCard;

const Description = styled.p`
  font-size: 16px;
  line-height: 26px;
  opacity: 0.7;
  max-width: 289px;
  @media (max-width: 767px) {
    max-width: 334px;
  }
`;

const Component = styled.div`
  position: relative;
  &:not(:last-child) {
    &::after {
      content: '';
      background-image: url(${WaveSvgUrl});
      position: absolute;
      top: 29px;
      left: 97px;
      width: 319px;
      height: 40px;
      background-repeat: no-repeat;
      @media (max-width: 1259px) {
        width: 240px;
      }
      @media (max-width: 1023px) {
        width: 160px;
      }
      @media (max-width: 767px) {
        background-image: url(${WaveMobileSvgUrl});
        width: 243px;
        height: 56px;
        top: unset;
        bottom: -78px;
        left: 0;
      }
    }
    &:nth-child(2n) {
      &::after {
        transform: scale(-1, 1);
        @media (max-width: 767px) {
          left: unset;
          right: 0;
          bottom: -97px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
    &:nth-child(2n) {
      text-align: right;
      ${Description} {
        margin-left: auto;
      }
    }
  }
`;

const ImageBlock = styled.div`
  margin-bottom: 26px;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  font-family: 'Playfair Display', sans-serif;
  margin-bottom: 15px;
`;
