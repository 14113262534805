import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';

export interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  dontClose: boolean;
}

function Overlay({ onClose, hidden, dontClose, ...rest }: OverlayProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!hidden) {
      lock(ref.current);
    }

    return () => clearBodyLocks();
  }, [hidden]);

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    /** if click occurs on overlay */
    if (event.target === event.currentTarget && !dontClose) {
      onClose();
    }
  }

  return (
    <Container ref={ref} onClick={handleClick} hidden={hidden} {...rest} />
  );
}

const Container = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  position: fixed;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  &[hidden] {
    display: none;
  }
`;

export default Overlay;
