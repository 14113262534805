import React from 'react';

import styled from 'styled-components';
import { colors } from '../../../../constants/theme';
import ContentContainer from '../../ContentContainer';
import TopFooter from './components/TopFooter';
import BottomFooter from './components/BottomFooter';

function Footer() {
  return (
    <Component>
      <ContentContainer>
        <TopFooter />
        <BottomFooter />
      </ContentContainer>
    </Component>
  );
}

export default Footer;

const Component = styled.footer`
  padding: 188px 0 78px;
  background-color: ${colors.green};
  z-index: 1;
  overflow: hidden;
  margin-bottom: -1px;
  @media (max-width: 1023px) {
    padding-top: 60px;
  }
`;
