import React from 'react';

import styled from 'styled-components';
import { colors } from '../../../../../constants/theme';
import RecommendedCard from './RecommendedCard';
import { ReactComponent as Marijuana1Svg } from '../../../../../assets/svg/home/marijuana-1.svg';
import { ReactComponent as Marijuana2Svg } from '../../../../../assets/svg/home/marijuana-2.svg';

function TopFooter() {
  return (
    <Component>
      <Inner>
        <Left>
          <Marijuana1Icon />
          <Marijuana2Icon />
          <RecommendedCard />
        </Left>
        <Right>
          <Title>
            Get A <span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Card to Access the Best <span>C</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> Products
          </Title>
          <Description>
            Receive Approval For Your <span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span> <span>M</span><span>a</span><span>r</span><span>i</span><span>j</span><span>u</span><span>a</span><span>n</span><span>a</span> Recommendation From
            State-Licensed <span>D</span><span>o</span><span>c</span><span>t</span><span>o</span><span>r</span><span>s</span>
          </Description>
        </Right>
      </Inner>
    </Component>
  );
}

export default TopFooter;

const Component = styled.div`
  padding-bottom: 188px;
  border-bottom: 1px solid ${colors.greenDark2};
  @media (max-width: 1023px) {
    padding-bottom: 90px;
  }
`;

const Inner = styled.div`
  transition: 0.3s;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  padding: 0 102px 0 0;
  position: relative;

  @media (max-width: 1023px) {
    padding: 0;
  }
`;

const Right = styled.div`
  padding-top: 64px;
  @media (max-width: 1023px) {
    text-align: center;
    margin-bottom: 80px;
    padding-top: 0;
  }
`;

const Marijuana1Icon = styled(Marijuana1Svg)`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-30%, -37%);
  @media (max-width: 1023px) {
    width: 213.83px;
    height: 169.52px;
    transform: translate(-31%, -52%) rotate(28deg);
  }
`;

const Marijuana2Icon = styled(Marijuana2Svg)`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-4%, 47%);
  z-index: 0;
  @media (max-width: 1023px) {
    width: 157.8px;
    height: 145.64px;
    transform: translate(47%, 44%) rotate(48deg);
  }
`;

const Title = styled.h2`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 53px;
  color: #fff;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 27px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${colors.greenLight2};
  max-width: 598px;
  @media (max-width: 767px) {
    max-width: 300px;
    margin: auto;
  }
`;
