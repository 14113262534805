import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  helperText?: string;
  postfix?: string;
}

function AutoWidthTextField({
  value,
  onChange,
  label,
  invalid,
  helperText,
  placeholder,
  readOnly,
  postfix,
  ...other
}: Props) {
  return (
    <Component>
      {label ? <Label>{label}</Label> : null}
      <InputLabel
        invalid={invalid ? invalid : !!helperText}
        value={value ? value : placeholder ?? ''}
        readOnly={readOnly}
      >
        <Field>
          {postfix && value ? <Postfix>{postfix}</Postfix> : null}
          <Input
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            size={1}
            postfix={postfix}
            {...other}
          />
        </Field>
      </InputLabel>
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </Component>
  );
}

export default AutoWidthTextField;

const Component = styled.div`
  position: relative;
`;

const InputLabel = styled.label<{
  value: string;
  invalid?: boolean;
  readOnly?: boolean;
}>`
  position: relative;
  display: inline-grid;
  padding: 15px 0;
  width: 100%;
  justify-content: start;
  cursor: text;
  border: 1px solid
    ${(props) => (props.invalid ? colors.red : colors.grayLight)};
  border-radius: 8px;
  transition: 0.3s;
  &::after {
    content: '';
    visibility: hidden;
    white-space: nowrap;
    grid-area: 1 / 2;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
    justify-content: start;
    overflow: hidden;
    padding: 0 20px;

    ${(props) =>
      props.value
        ? css`
            content: '${props.value}';
          `
        : null}
  }
  &:hover {
    border-color: ${colors.grayDark};
  }
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const Field = styled.div`
  display: inline-block;
  position: relative;
  grid-area: 1 / 2;
`;

const Postfix = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  position: absolute;
  top: 50%;
  transform: translate(100%, -50%);
  right: 0;
`;

const Input = styled.input<{
  invalid?: boolean;
  postfix?: string;
}>`
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  background-color: transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
  padding: 0 20px;
  &::placeholder {
    color: ${colors.gray};
    opacity: 1;
  }

  &:disabled {
    ${InputLabel} {
      background-color: ${colors.grayLight2};
      opacity: 1;
    }
  }

  &:not([readonly]):focus {
    ${InputLabel} {
      color: #24292e;
      background-color: #fff;
      border-color: ${(props) =>
        props.invalid
          ? colors.red
          : !props.readOnly
          ? colors.grayDark
          : colors.grayLight};
      outline: 0;
    }
  }
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  top: -5px;
  right: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    right: 10px;
    border-top: 5px solid ${colors.red};
  }
`;
