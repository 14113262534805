import axios from 'axios';

const request = axios.create({
  baseURL: 'https://us-central1-mmjcardamericaonline-57186.cloudfunctions.net/',
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

export { request };
