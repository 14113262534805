export const colors = {
  black: 'black',
  black100: '#101010',
  black200: '#262626',
  black300: '#3b3b3b',
  green: '#286641',
  greenDark: '#14572F',
  greenLight: '#28664010',
  greenLight2: '#629F7A',
  greenLight3: '#e1e7e2',
  greenDark2: '#367C52',
  gray: '#cacaca',
  grayLight: '#E5E5E5',
  grayLight2: '#F7F7F7',
  grayDark: '#BFBFBF',
  grayDark2: '#8E8E8E',
  red: '#FF544D',
  bodily: '#F8F5F1',
};
