import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { lock } from 'tua-body-scroll-lock';

import { ModalProps } from '../../ModalProvider';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import Loader from '../../Loader/Loader';

import PaymentForm from './components/PaymentForm';
import { colors } from '../../../constants/theme';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';

type Props = ModalProps<{
  clientId: string;
  firstName: string;
  lastName: string;
  plan: string;
  pix: string | null;
}>;

function PaymentModal({ closeModal, innerProps }: Props) {
  const { clientId, firstName, pix, lastName, plan } = innerProps;
  const scrollerBlockRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState(false);

  const { planData } = useAppSelector(
    (state) => ({
      planData: state.plan.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    lock(scrollerBlockRef.current);
  }, []);

  return (
    <Inner>
      <Close onClick={closeModal} />
      {isLoading ? <Loader color={colors.green} /> : null}
      <Title>Great!</Title>
      <ScrollerBlock ref={scrollerBlockRef}>
        <Description>
          The last step is left - you need to pay with your credit card. After
          the receipt of funds, our specialists will immediately process your
          application.
        </Description>
        <Amount>
          Payment amount<Price>${planData?.price}</Price>
        </Amount>
        <RecurlyProvider publicKey="ewr1-TZAIwcPilISuHDCPr2X84I">
          <Elements>
            <PaymentForm
              setLoading={setLoading}
              clientId={clientId}
              plan={plan}
              firstName={firstName}
              lastName={lastName}
              pix={pix}
            />
          </Elements>
        </RecurlyProvider>
      </ScrollerBlock>
    </Inner>
  );
}

export default PaymentModal;

const Inner = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 11;
  max-width: 472px;
  border-radius: 16px;
  padding: 40px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 30px 20px 20px;
    max-width: 335px;
  }
  @media (max-width: 374px) {
    height: calc((var(--vh, 1vh) * 100));
    width: 100vw;
    border-radius: unset;
  } ;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
  @media (max-width: 767px) {
    top: 35px;
  } ;
`;

const Title = styled.h2`
  font-weight: 400;
  font-family: 'Playfair Display', sans-serif;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  } ;
`;

const ScrollerBlock = styled.div`
  @media (max-width: 374px) {
    overflow: hidden auto;
    height: 100%;
    padding-bottom: 10px;
  } ;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 24px;
  } ;
`;

const Amount = styled.p`
  display: flex;
  justify-content: space-between;
  background: ${colors.grayLight2};
  border-radius: 8px;
  padding: 21.5px 20px;
  margin-bottom: 30px;
  align-items: center;
`;

const Price = styled.span`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
`;
