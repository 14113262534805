import React, { useEffect, useRef } from 'react';
import Imask, { MaskedPattern } from 'imask';
import TextInput from '../TextInput';
import styled from 'styled-components';
import { colors } from '../../constants/theme';

type MaskedTextInputProps = Omit<
  React.ComponentProps<typeof TextInput>,
  'onChange'
> & {
  mask: string;
  definitions?: MaskedPattern.Definitions;
  blocks?: MaskedPattern['blocks'];
  onChange?: (value: string) => void;
  label?: string;
  helperText?: string;
};

function MaskedTextInput({
  mask,
  definitions,
  blocks,
  onChange,
  value,
  label,
  helperText,
  invalid,
  ...textInputProps
}: MaskedTextInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const imaskRef = useRef<Imask.InputMask<any> | null>();

  useEffect(() => {
    if (!inputRef.current) return;

    if (!imaskRef.current) {
      imaskRef.current = Imask(inputRef.current, {
        mask,
        definitions,
        blocks,
      });
    }

    const imask = imaskRef.current;
    imask!.unmaskedValue = value as string;

    function acceptListener() {
      if (onChange) {
        onChange(imask.unmaskedValue);
      }
    }

    imaskRef.current.on('accept', acceptListener);

    return () => {
      imask.off('accept', acceptListener);
    };
  }, [blocks, definitions, mask, onChange]);

  return (
    <Component>
      {label ? <Label>{label}</Label> : null}
      <TextInput
        ref={inputRef}
        invalid={invalid ? invalid : !!helperText}
        {...textInputProps}
      />
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </Component>
  );
}

export default MaskedTextInput;

const Component = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  top: -5px;
  right: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    right: 10px;
    border-top: 5px solid ${colors.red};
  }
`;
