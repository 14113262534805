import { configureStore } from '@reduxjs/toolkit';

import planReducer from './plan';
import conditionsReducer from './conditions';
import satesReducer from './states';

export const store = configureStore({
  reducer: {
    plan: planReducer,
    conditions: conditionsReducer,
    states: satesReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
