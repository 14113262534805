import React from 'react';
import { useLocation } from 'react-router-dom';
export function createNamedContext<ContextValueType>(
  name: string,
  defaultValue: ContextValueType
): React.Context<ContextValueType> {
  const Ctx = React.createContext<ContextValueType>(defaultValue);
  Ctx.displayName = name;
  return Ctx;
}

/**
 * Reference:
 * https://gist.github.com/sw-yx/f18fe6dd4c43fddb3a4971e80114a052#file-createctx-nonullcheck-tsx-L3-L11
 */
export function createContextHookWithProvider<A>(
  name: string,
  defaultValue?: A
) {
  const context = createNamedContext<A | undefined>(name, defaultValue);
  function useCtx() {
    const ctx = React.useContext(context);
    if (!ctx) throw new Error('useCtx must be inside a Provider with a value');
    return ctx;
  }
  return [useCtx, context.Provider] as const;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
