import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Benefit1Icon } from '../../../../assets/svg/home/benefit-1.svg';
import { ReactComponent as Benefit2Icon } from '../../../../assets/svg/home/benefit-2.svg';
import { ReactComponent as Benefit3Icon } from '../../../../assets/svg/home/benefit-3.svg';
import { ReactComponent as Benefit4Icon } from '../../../../assets/svg/home/benefit-4.svg';
import { ReactComponent as Benefit5Icon } from '../../../../assets/svg/home/benefit-5.svg';
import { ReactComponent as Benefit6Icon } from '../../../../assets/svg/home/benefit-6.svg';
import NextArrowSvgUrl from '../../../../assets/svg/arrow-right-black.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import MedicalCard from './components/MedicalCard';
import { Element } from 'react-scroll';
import { colors } from '../../../../constants/theme';
SwiperCore.use([Navigation, Pagination]);

const RULES = [
  {
    icon: <Benefit1Icon />,
    title: 'Access for Ages 18 and Above',
    text:
      'Recreational and medical marijuana are both legal in Bakersfield. Anyone aged 18 and above can access medical cannabis legally after obtaining a doctor’s recommendation. For recreational use, the age limit is 21 and above.',
  },
  {
    icon: <Benefit2Icon />,
    title: 'Increased Possession Limits',
    text:
      'In Bakersfield, a patient seeking cannabis care can possess and consume higher limits of cannabis with the help of a medical cannabis card. The possession limit is eight times higher compared to the recreational limit.',
  },
  {
    icon: <Benefit3Icon />,
    title: 'Save on Taxes',
    text:
      'Medical cannabis patients are exempt from paying taxes. This makes it easier for people to purchase cannabis at an affordable price. As an MMJ patient, you will save up to an average of 28% or as high as 35% in some cities in California.',
  },
  {
    icon: <Benefit4Icon />,
    title: 'Wide Range of Products',
    text:
      'Recreational users have access to a limited amount of cannabis. In contrast, medical cannabis patients can access a wide range of medical cannabis products. Using a cannabis card, you can purchase higher limits of products and consume as required.',
  },
  {
    icon: <Benefit5Icon />,
    title: 'Legal Security',
    text:
      'You can possess, grow, and carry higher limits of cannabis without the fear of a possible arrest. A medical cannabis card also provides legal protection while driving provided the cannabis container is sealed, and you are carrying an MMJ recommendation.',
  },
  {
    icon: <Benefit6Icon />,
    title: 'Higher Growing Limits',
    text:
      'With the help of a medical cannabis recommendation, you can grow up to six mature cannabis plants indoors. This helps save you from the trouble of purchasing medical cannabis products repeatedly from state-licensed dispensaries.',
  },
];

function ThirdSection() {
  return (
    <Component>
      <Element name={'benefits'}>
        <ContentContainer>
          <Top>
            <Title>Benefits of having a <span>M</span><span>e</span><span>d</span><span>i</span><span>c</span><span>a</span><span>l</span>  <span>C</span><span>a</span><span>n</span><span>n</span><span>a</span><span>b</span><span>i</span><span>s</span> Card</Title>
            <SwiperButtons>
              <PrevButton className="swiper-button-prev" />
              <NextButton className="swiper-button-next" />
            </SwiperButtons>
          </Top>

          <SwiperBlock
            spaceBetween={10}
            slidesPerView={1}
            allowTouchMove={true}
            loop={true}
            breakpoints={{
              768: {
                spaceBetween: 20,
                slidesPerView: 2,
              },
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            {RULES.map((rule, index) => (
              <SwiperSlide key={rule.title}>
                <MedicalCard
                  title={rule.title}
                  text={rule.text}
                  icon={rule.icon}
                />
              </SwiperSlide>
            ))}
            <SwiperPagination className={'swiper-pagination'} />
          </SwiperBlock>
        </ContentContainer>
      </Element>
    </Component>
  );
}

export default ThirdSection;

const Component = styled.section`
  position: relative;
  padding-bottom: 138px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  padding: 0 33px;
  @media (max-width: 1023px) {
    padding: 0 30px 0 20px;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 400;
  line-height: 53px;
  font-family: 'Playfair Display', sans-serif;
  max-width: 495px;
  align-items: baseline;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 37px;
    max-width: unset;
  }
`;
const SwiperBlock = styled(Swiper)`
  overflow: visible;
  .swiper-slide {
    height: auto;
  }
`;
const NavigationButton = styled.div`
  padding: 39px;
  border-radius: 50px;
  border: 1px solid #e4e1dd;
  transition: 0.3s;
  cursor: pointer;
  &::after {
    content: url(${NextArrowSvgUrl});
    font-size: unset;
  }
  &:hover {
    border: 1px solid #d8d3ce;
  }
`;

const PrevButton = styled(NavigationButton)`
  left: 0;
  :after {
    transform: rotateY(180deg);
  }
`;

const NextButton = styled(NavigationButton)`
  right: 0;
  :after {
  }
`;

const SwiperButtons = styled.div`
  position: relative;
  display: flex;
  width: 170px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const SwiperPagination = styled.div`
  display: none;

  position: static;
  margin-top: 17px;
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 10px !important;
    background-color: #e4e1dd;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.green};
  }
  @media (max-width: 767px) {
    display: block;
  }
`;
