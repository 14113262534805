import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../../constants/theme';
import { ReactComponent as CheckedIcon } from '../../assets/svg/checked.svg';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  helperText?: string;
}

const BaseCheckbox = forwardRef<HTMLInputElement, Props>(function Checkbox(
  props,
  ref
) {
  const { checked, label, helperText, className, ...other } = props;

  return (
    <Label className={className} aria-label={String(label)}>
      <Checked checked={Boolean(checked)}>
        {checked ? <CheckedIcon /> : null}
      </Checked>
      {label && <LabelText checked={Boolean(checked)}>{label}</LabelText>}
      <Input checked={Boolean(checked)} type="checkbox" {...other} ref={ref} />
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </Label>
  );
});

export default BaseCheckbox;

const Checked = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${colors.grayLight};
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ${(props) =>
    props.checked
      ? css`
          background-color: ${colors.green};
        `
      : null}
`;

const Label = styled.label`
  position: relative;
  display: inline-flex;
  cursor: pointer;
  width: 100%;
`;

const LabelText = styled.span<{ checked: boolean }>`
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 22px;
  margin-left: 12px;
`;

const Input = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  left: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  bottom: 138%;
  white-space: nowrap;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    left: 10px;
    border-top: 5px solid ${colors.red};
  }
`;
