import React, {useEffect} from 'react';
import styled from 'styled-components';

import {colors} from '../../constants/theme';
import Header from './components/Header/Header';
import FirstSection from './components/FirstSection';
import SecondSection from './components/SecondSection/SecondSection';
import ThirdSection from './components/ThirdSection/ThirdSection';
import {useQuery} from '../../utils/common';
import ReactPixel from 'react-facebook-pixel';
import {gtag} from '../../services/gtag';
import Footer from './components/Footer/Footer';
import {fetchPlanThunk} from '../../store/plan';
import {useAppDispatch} from '../../store/hooks';

function Home() {
    const query = useQuery();
    const dispatch = useAppDispatch();

    const pix = query.get('pix');
    const getPlan = query.get('plan');
    const plan = getPlan ? getPlan : 'default';

    useEffect(() => {
        dispatch(fetchPlanThunk(plan));
    }, []);

    /** Connect analytics */
    useEffect(() => {
        gtag.event('page_view', {
            page_path: window.location.pathname,
            page_location: window.location.href,
            page_title: document.title,
            plan: plan
        });
        if (pix) {
            const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
            const options = {
                autoConfig: true,
                debug: false,
            };
            ReactPixel.init(pix, undefined, options);
            ReactPixel.pageView();
        }
    }, []);

    return (
        <Component>
            <Header/>
            <Main>
                <FirstSection/>
                <SecondSection/>
                <ThirdSection/>
            </Main>
            <Footer/>
        </Component>
    );
}

export default Home;

const Component = styled.div`
  border-bottom: 1px solid ${colors.grayLight};
`;

const Main = styled.main``;
