import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/theme';

interface Props {
  title: string;
  children?: React.ReactNode;
  description?: string;
}

function Section({ children, title, description }: Props) {
  return (
    <Component>
      <Header>
        <Title>{title}</Title>
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Header>
      {children}
    </Component>
  );
}
export default Section;

const Component = styled.section`
  margin-bottom: 80px;

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

const Header = styled.header`
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 40px;
  line-height: 53px;
  margin: 0 0 20px;
  font-family: 'Playfair Display', sans-serif;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 37px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
  span {
    color: ${colors.grayDark2};
  }
`;
