import React, { useMemo } from 'react';
import {
  useSelect,
  UseSelectState,
  UseSelectStateChangeOptions,
} from 'downshift';
import { OptionType } from '../../typings/model';
import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';
import { ReactComponent as ArrowDownSvg } from '../../assets/svg/arrow-down.svg';
import BaseCheckbox from '../BaseCheckbox';

interface Props {
  options: Array<OptionType>;
  value: Array<string>;
  onChange: (selectedOption: Array<string>) => void;
  placeholder?: string;
  label?: string;
  className?: string;
}

function DropdownMultipleSelect({
  options,
  placeholder,
  label,
  value,
  onChange,
  className,
}: Props) {
  function stateReducer(
    state: UseSelectState<OptionType>,
    actionAndChanges: UseSelectStateChangeOptions<OptionType>
  ) {
    const { changes, type } = actionAndChanges;
    switch (type) {
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
      case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: true, // keep menu open after selection.
          highlightedIndex: state.highlightedIndex,
        };
      default:
        return changes;
    }
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem: null,
    stateReducer,
    items: options,
    onSelectedItemChange: (changes) => {
      if (!changes.selectedItem) {
        return;
      }
      const index = value.indexOf(changes.selectedItem?.value ?? '');
      if (index > 0) {
        onChange([...value.slice(0, index), ...value.slice(index + 1)]);
      } else if (index === 0) {
        onChange([...value.slice(1)]);
      } else {
        onChange([...value, changes.selectedItem?.value ?? '']);
      }
    },
  });

  const currentOptionsLabels = useMemo(
    () =>
      options
        .filter((option) =>
          value.some((currentValue) => option.value === currentValue)
        )
        .map((currentOption) => currentOption.label),
    [options, value]
  );

  return (
    <DropDown className={className}>
      {label ? <Label>{label}</Label> : null}
      <DropDownDisplay
        type="button"
        isOpen={isOpen}
        {...getToggleButtonProps()}
      >
        {currentOptionsLabels.length ? (
          <DropDownDisplaySelect>
            {currentOptionsLabels.join(', ')}
          </DropDownDisplaySelect>
        ) : (
          <DropDownDisplayPlaceholder>
            {placeholder || 'DropDownSelect...'}
          </DropDownDisplayPlaceholder>
        )}

        <DropDownDisplayIcon />
      </DropDownDisplay>

      <OptionList {...getMenuProps()}>
        {isOpen &&
          options.map((option, index) => (
            <Item
              isHighlighted={highlightedIndex === index}
              checked={value.includes(option.value)}
              {...getItemProps({ item: option, index })}
            >
              <BaseCheckbox
                checked={value.includes(option.value)}
                key={`${option.value}${index}`}
                onChange={() => null}
                label={option.label}
              />
            </Item>
          ))}
      </OptionList>
    </DropDown>
  );
}

export default DropdownMultipleSelect;

const DropDown = styled.div`
  position: relative;
`;

const DropDownDisplayIcon = styled(ArrowDownSvg)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  fill: #9f9f9f;
  transition: transform 0.3s ease 0s;
`;

const DropDownDisplay = styled.button<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0 37px 0 20px;
  background: #fff;
  border: 1px solid
    ${(props) => (props.isOpen ? colors.grayDark : colors.grayLight)};

  border-radius: 8px;
  line-height: 15px;
  outline: none;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${DropDownDisplayIcon} {
        transform: translateY(-50%) rotate(180deg);
      }
    `}
`;

const DropDownDisplayText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 18.5px 0;
`;

const DropDownDisplaySelect = styled(DropDownDisplayText)`
  color: #000;
`;

const DropDownDisplayPlaceholder = styled(DropDownDisplayText)`
  color: #9f9f9f;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const OptionList = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  background-color: #fff;
  z-index: 1;
  max-height: 424px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: auto;
`;

const Item = styled.li<{ isHighlighted: boolean; checked: boolean }>`
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  ${(props) =>
    props.isHighlighted
      ? css`
          background-color: ${colors.grayLight2};
        `
      : null}

  ${(props) =>
    props.checked
      ? css`
          background-color: ${colors.greenLight};
        `
      : null};

  @media (max-width: 767px) {
    ${(props) =>
      !props.checked && props.isHighlighted
        ? css`
            background-color: unset;
          `
        : null}
  }
`;
