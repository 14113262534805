import React, { useRef } from 'react';
import styled from 'styled-components';
import TextInput from '../TextInput';
import { ReactComponent as AttachmentSvg } from '../../assets/svg/attachment.svg';
import { colors } from '../../constants/theme';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  helperText?: string;
}

function FileField({
  onChange,
  label,
  invalid,
  helperText,
  placeholder,
  className,
  ...other
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  function setInputValue() {
    if (inputRef.current && inputRef.current.files?.length) {
      return inputRef.current.files[0].name;
    }
  }

  return (
    <Component className={className}>
      {label ? <Label>{label}</Label> : null}
      <TextInput
        id={'file-upload'}
        onChange={onChange}
        type={'file'}
        ref={inputRef}
        {...other}
      />
      <FakeInputBlock htmlFor="file-upload">
        <FakeInput
          value={setInputValue()}
          placeholder={placeholder}
          readOnly={true}
          invalid={invalid ? invalid : !!helperText}
        />
        <AttachmentSvg />
      </FakeInputBlock>
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </Component>
  );
}

export default FileField;

const Component = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

const HelperText = styled.p`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  top: -5px;
  right: 0;
  background-color: ${colors.red};
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 100%;
    right: 10px;
    border-top: 5px solid ${colors.red};
  }
`;

const FakeInputBlock = styled.label`
  cursor: pointer;
  position: relative;
  display: block;
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
`;

const FakeInput = styled(TextInput)`
  pointer-events: none;
  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 40px 15px 20px;
  }
`;
