import { FormikErrors } from 'formik';
import moment from 'moment';
import { Values } from './FormPage';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { postcodeValidator } from 'postcode-validator';
import React from 'react';

export const VERIFYING_DESCRIPTION =
  'In order to verify your legal name and identity, you must upload a government-issued photo ID card such as driver’s license or passport.';

export const CONFIRMATION_DESCRIPTION =
  'By electronically signing this document, you declare that the information on this form is true and correct. Additionally, you are also aware that your recommendation may be revoked at any time if you mispresented yourself on this form.\n' +
  '<br/><br/>\n' +
  '<b>Note: Filling of this questionare does not guarantee a medical marijuana recommendation.</b>\n' +
  '<br/><br/>\n' +
  'I give my consent to telemedicine. <span>(Telehealth means the mode of delivering health care services and public health via information and communication technologies to facilitate the diagnosis, consultation, treatment, education, care management, and self-management of a patient’s health care.)</span>\n' +
  '<br/><br/>\n' +
  'I declare the following to be true:';

export function validate(values: Values) {
  const errors: FormikErrors<Values> = {};

  const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])? ?\r?)*$/;

  if (!values.firstName) {
    errors.firstName = 'Fill in the field';
  }
  if (!values.lastName) {
    errors.lastName = 'Fill in the field';
  }
  if (!emailRegExp.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!values.phone) {
    errors.phone = 'Fill in the field';
  } else if (!isValidPhoneNumber(values.phone, 'US')) {
    errors.phone = 'Phone number is invalid';
  }

  if (!values.year) {
    errors.year = 'Fill in the field';
  }

  if (!values.month) {
    errors.month = 'Fill in the field';
  }

  if (!values.day) {
    errors.day = 'Fill in the field';
  }

  if (
    values.year &&
    values.month &&
    values.day &&
    !moment(values.date, 'YYYY-MM-DD', true).isValid()
  ) {
    errors.date = 'Please enter valid date';
  }
  if (!values.height) {
    errors.height = 'Fill in the field';
  }
  if (!values.weight) {
    errors.weight = 'Fill in the field';
  }
  if (!values.state) {
    errors.state = 'Fill in the field';
  }
  if (!values.zip) {
    errors.zip = 'Fill in the field';
  } else if (!postcodeValidator(values.zip, 'US')) {
    errors.zip = 'Fill in the field';
  }
  if (!values.city) {
    errors.city = 'Fill in the field';
  }
  if (!values.address) {
    errors.address = 'Fill in the field';
  }
  if (!values.stateId) {
    errors.stateId = 'Fill in the field';
  }
  if (!values.id_card) {
    errors.id_card = 'Upload the file';
  }
  if (!values.signature) {
    errors.signature = 'Please add your signature';
  }
  if (!values.agreement) {
    errors.agreement = 'Please agree with terms and conditions';
  }

  return errors;
}

export function validateOnlyNumberAndLength(
  event: React.ChangeEvent<HTMLInputElement>,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  length?: number
) {
  const reg = new RegExp('^\\d*$');
  const value = event.target.value;

  if (value.length <= (length ?? value.length) && reg.test(value)) {
    handleChange(event);
  }
  // handleChange(event);
}
