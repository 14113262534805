import React from 'react';

import * as S from './TextInput.style';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  invalid?: boolean;
};

export type InputRef = HTMLInputElement;

function TextInput(
  { className, disabled, invalid, ...rest }: Props,
  ref: React.Ref<InputRef>
) {
  return (
    <S.Container className={className}>
      <S.Input
        autoComplete={'none'}
        ref={ref}
        {...rest}
        disabled={disabled}
        invalid={invalid}
      />
    </S.Container>
  );
}

export default React.forwardRef<InputRef, Props>(TextInput);
