import React from 'react';
import styled from 'styled-components';
import TextField from '../../../components/TextField';
import { useFormikContext } from 'formik';
import { Values } from '../FormPage';
import { Element } from 'react-scroll';
import MaskedTextInput from '../../../components/MaskedTextInput';

function ContactInfo() {
  const {
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
  } = useFormikContext<Values>();

  function handleChangeClick(value: string) {
    setFieldValue('phone', value);
  }
  return (
    <Component>
      <FirstBlock>
        <Element name={'firstName'}>
          <Input
            name={'firstName'}
            value={values.firstName}
            onChange={handleChange}
            label={'First Name'}
            placeholder={'As on your driver’s license'}
            helperText={
              errors.firstName && touched.firstName ? errors.firstName : ''
            }
          />
        </Element>
        <Element name={'middleName'}>
          <Input
            name={'middleName'}
            value={values.middleName}
            onChange={handleChange}
            label={'Middle Name'}
            helperText={
              errors.middleName && touched.middleName ? errors.middleName : ''
            }
          />
        </Element>
        <Element name={'lastName'}>
          <Input
            name={'lastName'}
            value={values.lastName}
            onChange={handleChange}
            label={'Last Name'}
            helperText={
              errors.lastName && touched.lastName ? errors.lastName : ''
            }
          />
        </Element>
      </FirstBlock>
      <SecondBlock>
        <Element name={'email'}>
          <Input
            name={'email'}
            value={values.email}
            onChange={handleChange}
            label={'Email'}
            placeholder={'you@example.com'}
            helperText={errors.email && touched.email ? errors.email : ''}
          />
        </Element>
        <Element name={'phone'}>
          <MaskedTextInput
            name={'phone'}
            value={values.phone}
            onChange={handleChangeClick}
            label={'Phone'}
            placeholder={'XXX-XXX-XXXX'}
            helperText={errors.phone && touched.phone ? errors.phone : ''}
            type="tel"
            mask={'000-000-0000'}
          />
        </Element>
      </SecondBlock>
    </Component>
  );
}

export default ContactInfo;

const Component = styled.div``;

const Input = styled(TextField)`
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const FirstBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    display: block;
    margin-bottom: unset;
  }
`;

const SecondBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  @media (max-width: 767px) {
    display: block;
  }
`;
